<template>

  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>

        环境名称：
        <el-input placeholder="请输入环境名称"
                  style="width: 120px;  margin-right: 15px;"
                  size="mini"></el-input>
        创建人：
        <el-input placeholder="请输入环境名称"
                  style="width: 120px;"
                  size="mini"></el-input>
        <el-button type="primary"
                   size="mini"
                   style="margin-left: 10px;">查询</el-button>
        <el-button type="primary"
                   size="mini"
                   style="margin-left: 10px;"
                   icon="el-icon-plus"
                   @click="dialogEnvVisible = true">新增监管</el-button>
      </div>

    </el-card>
    <el-card>

      <el-table :data="superviseData"
                stripe
                style="width: 60%">
        <el-table-column type="index"
                         :index="indexMethod"
                         label="序号"
                         width="50">
        </el-table-column>

        <el-table-column label="监管名称"
                         width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.brand_id == '00'">
              <el-tag type="success"
                      effect="dark">{{ scope.row.brand_type }}</el-tag>
              -{{ scope.row.name }}
            </div>
            <div v-if="scope.row.brand_id == '01'">
              <el-tag type="warning"
                      effect="dark"
                      style="width: 35.64px;">{{ scope.row.brand_type }}</el-tag>
              -{{ scope.row.name }}
            </div>
            <div v-if="scope.row.brand_id == '02'">
              <el-tag type="info"
                      effect="dark">{{ scope.row.brand_type }}</el-tag>
              -{{ scope.row.name }}
            </div>
            <div v-if="scope.row.brand_id == '03'">
              <el-tag type="danger"
                      effect="dark">{{ scope.row.brand_type }}</el-tag>
              -{{ scope.row.name }}
            </div>

          </template>
        </el-table-column>

        <el-table-column prop="table_name"
                         label="监管地址">
        </el-table-column>

        <el-table-column prop="creator"
                         label="创建人"
                         width="80">
        </el-table-column>
        <el-table-column prop="create_time"
                         label="创建时间"
                         width="200">
        </el-table-column>
        <el-table-column label="操作"
                         width="150">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.$index, scope.row)">查看</el-button>
            <el-button size="mini"
                       @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="新增监管"
               :visible.sync="dialogEnvVisible"
               :close-on-click-modal="false"
               width="500px">
      <el-form :model="superviseForm">
        <el-form-item label="品牌："
                      :label-width="formLabelWidth">
          <el-select v-model="superviseForm.brand_type"
                     @change="setBrand($event)"
                     placeholder="选择品牌">
            <el-option v-for="item in dbList"
                       :key="item.key"
                       :label="item.value"
                       :value="item"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="监管名称："
                      :label-width="formLabelWidth">
          <el-input v-model="superviseForm.supervise_name"
                    autocomplete="off"
                    style="width: 221px;"></el-input>
        </el-form-item>
        <el-form-item label="监管数据库："
                      :label-width="formLabelWidth">
          <el-input v-model="superviseForm.db_name"
                    autocomplete="off"
                    style="width: 221px;"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeVisible">取 消</el-button>
        <el-button type="primary"
                   @click="addSupervise">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {

  data () {
    return {
      activeName: '', // 当前活动菜单名称
      activePath: '', // 当前活动菜单 路由
      parentName: '', // 当前活动菜单，父级菜单名称
      activeTabName: 'first',

      dialogEnvVisible: false,
      formLabelWidth: '120px',
      superviseData: {
        envName: '', // 环境名称
        envUrl: '', // 环境地址
        db: '', // 数据库

      },
      superviseForm: {
        brand_type: '', // 品牌类型
        brand_id: '', // 品牌id
        supervise_name: '', //监管名称
        db_name: '' //监管数据库地址

      },
      queryData: {
        brand_id: '',
        brand_type: ''


      },
      dbList: [


      ],


    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.getSuperviseList()
    this.getBrand()
  },

  methods: {

    async addSupervise () {
      const { data: res } = await this.$http.post("py/sever/env/addSupervise", this.superviseForm)
      if (res.success) {
        this.dialogEnvVisible = false
        this.superviseForm = {
          brand_type: '', // 品牌类型
          brand_id: '', // 品牌id
          supervise_name: '', //监管名称
          db_name: '' //监管数据库地址

        }
        this.$message.success(res.msg)

      } else {
        this.$message.error(res.msg)
      }
    },
    setBrand (item) {
      this.superviseForm.brand_id = item.key
      this.superviseForm.brand_type = item.value
    },
    closeVisible () {
      this.dialogEnvVisible = false
      this.superviseForm = {
        brand_type: '', // 品牌类型
        brand_id: '', // 品牌id
        supervise_name: '', //监管名称
        db_name: '' //监管数据库地址

      }
    },
    async getSuperviseList () {
      const { data: res } = await this.$http.post('py/sever/env/getSupervise', this.queryData)
      if (res.success) {
        this.superviseData = res.data

      } else {
        this.$message.error(res.msg)
      }
    },
    async getBrand () {
      const { data: res } = await this.$http.get('py/sever/env/getBrand')
      this.dbList = res.data
    }


  }
}
</script>

<style lang="less" scoped>
</style>
