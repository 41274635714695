<!--编辑排期页面-->
<template>
  <div>
    <el-card>
      <div style="margin-left: 30%;">
        <div style="display:inline-block;">
          <span v-if="anticipatedTestDate != null">{{"预计提测时间：" + anticipatedTestDate }}</span>
          <span v-else>{{"预计提测时间：暂无"}}</span>
        </div>
        <div style="display:inline-block;">
          <span v-if="anticipatedOnlineDate != null">{{"-------预计上线时间：" + anticipatedOnlineDate}}</span>
          <span v-else>{{"-------预计上线时间：暂无"}}</span>
        </div>

      </div>
      <div style="position: relative;margin-left: 30%;margin-top: 30px;">
        <el-button icon="el-icon-plus"
                   style="width: 400px;border-color:#002366;"
                   @click="openVisible">添加排期</el-button>

      </div>
      <div class="scheduleInfo">
        <el-table :data="scheduleLists"
                  style="width: 100%"
                  border="">

          <el-table-column label="录入人"
                           width="180"
                           autocomplete="off">
            <template slot-scope="scope">

              <el-input v-model="scope.row.creatorName"
                        autocomplete="off"
                        size="small"
                        disabled></el-input>

            </template>
          </el-table-column>
          <el-table-column label="角色"
                           width="180">
            <template slot-scope="scope">

              <el-input v-model="scope.row.roleStr"
                        autocomplete="off"
                        size="small"
                        disabled></el-input>

            </template>
          </el-table-column>
          <el-table-column label="环节"
                           width="180">
            <template slot-scope="scope">

              <el-input v-model="scope.row.link"
                        autocomplete="off"
                        size="small"
                        disabled></el-input>

            </template>
          </el-table-column>
          <el-table-column label="工时(天/人)"
                           width="180">
            <template slot-scope="scope">

              <el-input v-model="scope.row.workHour"
                        autocomplete="off"
                        size="small"
                        placeholder="工时(天/人)"
                        disabled></el-input>

            </template>
          </el-table-column>
          <el-table-column label="周期"
                           width="400">
            <template slot-scope="scope">
              <div class="block">

                <el-date-picker v-model="scope.row.circleTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                disabled>
                </el-date-picker>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="70px">
            <template slot-scope="scope">
              <div v-show="is_show_delete">
                <el-button v-if="scope.row.creatorCode === accountCode"
                           type="danger"
                           icon="el-icon-delete"
                           circle
                           @click="deleteModule(scope.$index, scope.row)"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-card>

    <el-dialog title="添加排期"
               :visible.sync="scheduledVisible"
               width="30%"
               :close-on-click-modal="false"
               modal>
      <el-form :model="scheduleForm"
               :rules="scheduleFormRules"
               ref="scheduleFormRef">

        <el-form-item label="环节："
                      :label-width="formLabelWidth"
                      prop="link">

          <el-select v-model="scheduleForm.link"
                     placeholder="请选择分类">
            <el-option v-for="item in linkType"
                       :key="item.id"
                       :label="item.value"
                       :value="item.value"></el-option>

          </el-select>

        </el-form-item>
        <el-form-item label="工时："
                      :label-width="formLabelWidth"
                      prop="workHour">
          <el-input autocomplete="off"
                    v-model="scheduleForm.workHour"
                    size="small"
                    placeholder="工时(天/人)"></el-input>

        </el-form-item>
        <el-form-item label="周期："
                      :label-width="formLabelWidth"
                      prop="cycleTime">
          <el-date-picker v-model="scheduleForm.cycleTime"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeVisible">取消</el-button>
        <el-button type="primary"
                   @click="addSchedule">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    activename: { type: String, default: '' }
  },

  data () {
    return {
      is_show_type: false,
      is_show_delete: false,
      demandInfo: {},
      scheduleLists: [

      ], // 当前需求，排期列表页
      scheduleForm: { // 排期的需求内容
        demandId: '', // 需求id
        link: '', // 环节
        workHour: '', // 工时
        cycleTime: [], // 开始日期和结束日期的集合
        creator: '',
        role: ''

      },
      scheduleFormRules: {
        link: [
          { required: true, message: '环节不能为空', trigger: 'blur' }
        ],
        workHour: [
          { required: true, message: '工时不能为空', trigger: 'blur' }

        ],
        cycleTime: [
          { required: true, message: '周期不能为空', trigger: 'blur' }
        ]
      },
      Form: {
        demandId: '', // 需求id
        link: '', // 环节
        workHour: '', // 工时
        startDate: '', // 开始时间
        endDate: ''// 结束时间
      },

      scheduledVisible: false,

      formLabelWidth: '120px',
      value: '',
      demandDetail: {},
      pageQuery: {
        demandId: '',
        current: 1,
        size: 99

      },
      anticipatedOnlineDate: '', // 预计上线时间
      anticipatedTestDate: '', // 预计提测时间

      linkType: {}, // 获取排期环节,
      accountCode: '' // 当前登录人

    }
  },

  created () {
  },
  watch: {
    activename () {
      if (this.activename == 'second') {

        this.schedule_init()
      }
    }
  },

  methods: {
    schedule_init () {
      this.demandId = window.sessionStorage.getItem('demandId')
      this.pageQuery.demandId = this.demandId
      this.scheduleForm.demandId = this.demandId
      this.getScheduleInfoVO()

    },
    async addSchedule () {
      this.$refs.scheduleFormRef.validate(async (valid) => {
        if (!valid) return
        this.scheduledVisible = true
        this.Form.startDate = this.scheduleForm.cycleTime[0]
        this.Form.endDate = this.scheduleForm.cycleTime[1]
        this.Form.demandId = this.scheduleForm.demandId
        this.Form.link = this.scheduleForm.link
        this.Form.workHour = this.scheduleForm.workHour
        const { data: res } = await this.$http.post('py/server/project/addSchedule', this.Form)

        if (!res.success) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.scheduledVisible = false

        this.scheduleForm = { // 排期的需求内容
          demandId: '', // 需求id
          link: '', // 环节
          workHour: '', // 工时
          cycleTime: [], // 开始日期和结束日期的集合
          creator: '',
          role: ''

        }
        // this.scheduleForm.demandId = this.demandId
        this.schedule_init()
      })

      // this.scheduledVisible = true
      // this.Form.startDate = this.scheduleForm.cycleTime[0]
      // this.Form.endDate = this.scheduleForm.cycleTime[1]
      // this.Form.demandId = this.scheduleForm.demandId
      // this.Form.link = this.scheduleForm.link
      // this.Form.workHour = this.scheduleForm.workHour
      // const { data: res } = await this.$http.post('py/server/project/addSchedule', this.Form)

      // if (!res.success) return this.$message.error(res.msg)
      // this.$message.success(res.msg)
      // this.scheduledVisible = false

      // this.scheduleForm = { // 排期的需求内容
      //   demandId: '', // 需求id
      //   link: '', // 环节
      //   workHour: '', // 工时
      //   cycleTime: [], // 开始日期和结束日期的集合
      //   creator: '',
      //   role: ''

      // }
      // this.scheduleForm.demandId = this.demandId
      // this.getScheduleInfoVO()
    },

    async deleteModule (x, row) {
      // const index = this.scheduleLists.indexOf(item) // 找到对应图标的下标

      const { data: res } = await this.$http.post('py/server/project/deleteSchedule?id=' + row.id)
      if (!res.success) return this.$message.error(res.msg)

      this.$message.success('删除成功')
      if (x !== -1) {
        this.scheduleLists.splice(x, 1)
      }
      this.getScheduleInfoVO()
    },

    async getScheduleInfoVO () {
      // 获取排期列表页
      const { data: res } = await this.$http.post('py/server/project/getScheduleInfoVO?id=' + this.pageQuery.demandId)
      if (!res.success) return this.$message.error(res.msg)

      this.scheduleLists = res.data.list

      this.anticipatedOnlineDate = res.data.anticipatedOnlineDate
      this.anticipatedTestDate = res.data.anticipatedTestDate

      for (let i = 0; i < this.scheduleLists.length; i++) {

        this.scheduleLists[i].circleTime = [res.data.list[i].startDate, res.data.list[i].endDate]
      }
      this.accountCode = window.sessionStorage.getItem('accountCode')


    },
    async getLinkType () {
      const { data: res } = await this.$http.get('py/server/project/getLinkType')
      if (!res.success) return this.$message.error(res.msg)
      this.linkType = res.data
    },
    closeVisible () {
      this.scheduleForm = { // 排期的需求内容
        demandId: '', // 需求id
        link: '', // 环节
        workHour: '', // 工时
        cycleTime: [], // 开始日期和结束日期的集合
        creator: '',
        role: ''

      }
      this.scheduledVisible = false
      this.scheduleForm.demandId = this.demandId
    },
    async getDemandInfoBaseVO () {
      // 查询需求的基本信息
      const { data: res } = await this.$http.post('/py/server/project/getDemandInfoBaseVO?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.demandInfo = res.data
      this.is_show()
    },
    is_show () {
      if (this.demandInfo.demandStatus === '08') {
        this.is_show_type = false
        this.is_show_delete = false
      } else {
        this.is_show_type = true
        this.is_show_delete = true
      }
    },
    openVisible () {
      this.scheduledVisible = true
      this.getLinkType()
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 20px;
}
.scheduleInfo {
  margin: auto;
}
</style>
