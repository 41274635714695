<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
      <el-breadcrumb-item>BUG详情页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-page-header @back="goBack"
                      content="BUG详情页"
                      style="margin-bottom: 30px;">
      </el-page-header>

      <div style="position: absolute;z-index: 999;top:13.5%;right:10%">
        <el-button v-if="accountCode === handleCode"
                   size="mini"
                   type="warning"
                   @click="updateBugStatus('00')"
                   v-show="rdButton">bug移交</el-button>
        <el-button size="mini"
                   v-if="accountCode === handleCode"
                   type="success"
                   @click="updateBugStatus('01')"
                   v-show="rdButton">已解决</el-button>

        <el-button size="mini"
                   v-if="accountCode === handleCode"
                   type="danger"
                   @click="updateBugStatus('04')"
                   v-show="rdButton">驳回</el-button>
        <el-button size="mini"
                   v-if="accountCode === handleCode"
                   type="warning"
                   @click="updateBugStatus('02')"
                   v-show="testButton">重新打开</el-button>
        <el-button size="mini"
                   v-if="accountCode === handleCode"
                   type="info"
                   @click="updateBugStatus('03')"
                   v-show="testButton">关闭</el-button>
        <el-button size="mini"
                   @click="goBack()">返回</el-button>
      </div>

      <el-form :model="bugDetail"
               label-width="100px">

        <el-form-item label="bug标题："
                      prop="name">
          <span class="bugTitle">{{bugDetail.title}}</span>

        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="类别："
                          prop="bugType">
              <el-select v-model="bugDetail.typeStr"
                         placeholder="请选择活动区域"
                         disabled>
                <el-option label="代码bug"
                           value="shanghai"></el-option>
                <el-option label="需求缺陷"
                           value="beijing"></el-option>
                <el-option label="页面优化"
                           value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="优先级："
                          prop="level">
              <el-select v-model="bugDetail.levelStr"
                         size="small"
                         placeholder="请选择优先级"
                         disabled>
                <el-option label="紧急"
                           value="紧急"></el-option>
                <el-option label="严重"
                           value="严重"></el-option>
                <el-option label="一般"
                           value="一般"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="指派给："
                          prop="handlers">
              <el-select v-model="bugDetail.handleName"
                         @change="getHander($event)"
                         placeholder="请选择指派人">
                <el-option v-for="item in this.userList"
                           :key="item.key"
                           :label="item.value"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="描述："
                      prop="content"
                      size="small">
          <el-card body-style="backgroundColor:#FFFAF0">
            <p v-html="bugDetail.content"
               style="width: 1200px;"
               size="small"></p>

          </el-card>

        </el-form-item>
        <el-form-item label="历史记录："
                      size="small">
          <el-card body-style="backgroundColor:#FFFAF0">
            <el-row>
              <el-timeline :reverse="true">
                <el-timeline-item v-for="(item,index) in remarksList"
                                  :key="index"
                                  placement="top"
                                  :timestamp="item.author +  ' 提交于 ' +  item.createTime">
                  <h5 v-html="item.content"></h5>
                </el-timeline-item>
              </el-timeline>
            </el-row>

          </el-card>
        </el-form-item>
        <el-form-item label="备注："
                      prop="remarks"
                      size="small">
          <EmptyRichText style="margin-left:0px;width: 1250px;"
                         v-model="bugDetail.remarks"
                         ref="hahaha"
                         size="mini"></EmptyRichText>

        </el-form-item>

      </el-form>
    </el-card>

  </div>
</template>

<script>
import EmptyRichText from '../../common/EmptyRichText.vue'
export default {
  components: {
    EmptyRichText
  },
  data () {
    return {
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      bugDetail: {

      },
      roleType: '',
      remarksList: [], // 历史记录
      activeTabName: 'fourth',
      bugId: '', // bugID
      demandId: '', // 需求ID
      userList: '',
      updateBugForm: {
        demandId: '',
        id: '',
        handleCode: '', // 负责人编码
        handleName: '', // 负责人名称
        status: '',
        level: '',
        type: '',
        title: '',
        content: '', // 备注内容
        remarks: '1111'

      },
      accountCode: '',
      handleCode: ''
    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.roleType = window.sessionStorage.getItem('roleType')
    this.accountCode = window.sessionStorage.getItem('accountCode')
    this.getBugId()
    this.isShow()
    this.getBugDetail()
  },
  rdButton: false,
  testButton: false,
  methods: {
    goBack () {
      window.sessionStorage.setItem('tabName', this.activeTabName)
      this.$router.back(0)
    },
    getBugId () {
      this.bugId = this.$route.query.id
      this.demandId = this.$route.query.demandId
      this.handleCode = this.$route.query.handleCode
      // console.log(this.bugId)
      // console.log(this.demandId)
      this.getUserListByDemand()
    },
    async getBugDetail () {
      const { data: res } = await this.$http.post('py/server/project/getBugDetail?id=' + this.bugId)
      if (!res.success) return this.$message.error(res.msg)
      this.remarksList = res.data.list
      this.bugDetail = res.data.bugInfo
    },
    async getUserListByDemand () {
      const { data: res } = await this.$http.get('py/server/project/getUserListByDemand?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    async updateBugStatus (bugStatus) {
      this.updateBugForm.demandId = this.bugDetail.demandId
      this.updateBugForm.content = this.$refs.hahaha.content

      this.updateBugForm.id = this.bugDetail.id

      this.updateBugForm.remarks = this.bugDetail.remarks
      this.updateBugForm.title = this.bugDetail.title
      this.updateBugForm.type = this.bugDetail.type
      this.updateBugForm.level = this.bugDetail.level
      this.updateBugForm.handleCode = this.bugDetail.handleCode
      this.updateBugForm.handleName = this.bugDetail.handleName
      this.updateBugForm.status = bugStatus

      const { data: res } = await this.$http.post('py/server/project/updateBugStatus', this.updateBugForm)
      if (!res.success) return this.$message.error(res.msg)
      this.$message.success('请求成功')
      this.goBack()
    },
    getHander (item) {
      /**
       * @description: 监听负责人，变更后赋值
       */
      this.bugDetail.handleCode = item.key
      this.bugDetail.handleName = item.value
    },
    isShow () {
      if (this.roleType === '前端开发' || this.roleType === '后端开发') {
        this.rdButton = true
      } else {
        this.rdButton = false
      }
      if (this.roleType === '测试') {
        this.testButton = true
      } else {
        this.testButton = false
      }
    }

  }
}
</script>

<style lang="less" scoped>
.bugTitle {
  display: block;
  font-size: 18px;
  font-weight: bold;
}
</style>
