<template>
  <div>

    <el-card>
      <div class="titleitem">

      </div>
      <el-table :data="params_data"
                height="120px"
                stripe>

        <el-table-column prop="key"
                         label="key">
          <template slot-scope="scope">
            <el-input v-model="scope.row.key"
                      placeholder="key"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="value"
                         label="value">
          <template slot-scope="scope">
            <el-input v-model="scope.row.value"
                      placeholder="value"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="
                      operate"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="success"
                       icon="el-icon-save"
                       @click="handlesaveAnnualAssessment(scope.$index, scope.row)">保存
            </el-button>
            <el-button size="mini"
                       type="danger"
                       icon="el-icon-delete"
                       @click="deleteRow(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-button type="primary"
                   icon="el-icon-plus"
                   size="mini"
                   style="width: 100%;"
                   @click="addRow">新增一行
        </el-button>
      </div>
      <el-button @click="onClick">保存保存</el-button>
    </el-card>
  </div>

</template>
 
<script>
export default {
  name: 'apiTable',
  data () {
    return {
      options: [],
      value: '',
      value1: '',
      month1: '',
      month2: '',

      params_data: [{
        key: '合格',
        value: '考核通过',
      },
      {
        key: 'token',
        value: 'sjfsljflslkfsf',
      }]
    }
  },
  watch: {},
  mounted () {

  },
  methods: {


    //增加行
    addRow () {
      if (this.params_data == undefined) {
        this.params_data = new Array();
      }
      let obj = {};

      this.params_data.push(obj);
    },
    //保存年度考核行
    handlesaveAnnualAssessment (a, b) {
      console.log(a)
      console.log(b)
    },
    //删除行
    deleteRow (index) {
      console.log(index);
      this.params_data.splice(index, 1)
    },
    onClick () {
      console.log(this.params_data)
    }
  }
}
</script>
