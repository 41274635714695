<!--数据工具-短信数据查询-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区域 -->
    <el-card>
      <div>
        <el-form inline
                 :model="pageQuery">
          <el-form-item prop="mobile"
                        label="手机号">
            <el-input v-model="pageQuery.mobile"></el-input>
          </el-form-item>

          <el-form-item label="环境">
            <el-input placeholder="请选择环境"
                      v-model="pageQuery.envStr">
              <el-select v-model="pageQuery.env"
                         @change="setQuery($event)"
                         slot="prepend"
                         placeholder="请选择">
                <el-option v-for="item in env"
                           :key="item.key"
                           :label="item.value"
                           :value="item"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="mini"
                       @click="getPageList">查询数据</el-button>
          </el-form-item>
        </el-form>

        <template>
          <el-table :data="pageList"
                    height="700"
                    :header-cell-style="{ 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }"
                    border
                    style="width: 100%"
                    stripe>
            <el-table-column type="index"
                             width="50"> </el-table-column>
            <el-table-column prop="mobile"
                             label="手机号"
                             width="150">
            </el-table-column>
            <el-table-column prop="content"
                             label="内容"
                             width="800">
            </el-table-column>
            <el-table-column prop="createTime"
                             label="创建日期"
                             width="220">
            </el-table-column>
          </el-table>
        </template>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageQuery.current"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="pageQuery.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>

export default {

  created () {
    this.getEnv()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
  },
  data () {
    return {
      pageList: [],
      total: 0,
      pageQuery: {
        current: 1,
        size: 10,
        mobile: '',
        env: '',
        envStr: ''
      },

      env: [],
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: ''
    }
  },
  methods: {
    async getPageList () {
      const { data: res } = await this.$http.post(
        'api/server/test/data/getRecordList',
        this.pageQuery
      )
      if (res.success) {
        this.pageList = res.data.records
        this.total = res.data.total
      }
    },
    async getEnv () {
      const { data: res } = await this.$http.get(
        'api/server/test/data/getDataSource'
      )
      this.env = res.data
    },
    setQuery (e) {
      this.pageQuery.env = e.key
      this.pageQuery.envStr = e.value
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage
      this.getPageList()
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent
      this.getPageList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
