<!--添加bug弹窗页面-->
<template>
  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
      <el-breadcrumb-item>新增BUG</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-page-header @back="goBack"
                      content="新建Bug"
                      style="margin-bottom: 30px;">
      </el-page-header>

      <el-form :model="bugForm"
               :rules="bugDetailRules"
               ref="bugForm"
               label-width="100px">
        <el-form-item label="bug标题"
                      prop="title">
          <el-input v-model="bugForm.title"
                    style="width: 1200px;"
                    size="small"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="Bug类型"
                          prop="type">
              <el-select v-model="bugForm.type"
                         placeholder="请选择活动区域">
                <el-option v-for="item in this.bugType"
                           :key="item.key"
                           :label="item.value"
                           :value="item.key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="优先级"
                          prop="level">
              <el-select v-model="bugForm.level"
                         size="small"
                         placeholder="请选择优先级">
                <el-option v-for="item in this.bugLevel"
                           :key="item.key"
                           :label="item.value"
                           :value="item.key"></el-option>

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="指派给："
                          prop="handleName">
              <el-select v-model="bugForm.handleName"
                         @change="getHander($event)"
                         placeholder="请选择指派人">
                <el-option v-for="item in this.userList"
                           :key="item.code"
                           :label="item.value"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="描述"
                      prop="content"
                      size="small">
          <BugRichText style="margin-left:0px;width: 1250px;"
                       v-model="bugForm.content"
                       ref="hahaha"
                       size="mini"></BugRichText>

        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     v-on:click="submitForm('bugForm')">立即创建</el-button>

        </el-form-item>
      </el-form>

    </el-card>

  </div>
</template>

<script>
import BugRichText from '../bug/BugRichText.vue'

export default {
  components: {
    BugRichText
  },
  data () {
    return {
      bugForm: {
        title: '', // bug标题
        handleCode: '', // 负责人编码
        handleName: '', // 负责人名称
        type: '', // bug类型
        level: '', // bug级别
        demandId: '', // 需求ID
        content: '' // bug内容

      },

      bugType: '', // bug类型字典项
      bugLevel: '', // bug等级
      demandId: '', // 需求id
      userList: '', // 需求指派人列表

      bugDetailRules: {
        title: [
          { required: true, message: '请输入bug标题', trigger: 'blur' }

        ],
        level: [
          { required: true, message: '请选择bug级别', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择bug类别', trigger: 'blur' }
        ],
        handleName: [
          { required: true, message: '请选择指派人', trigger: 'blur' }
        ]

      },

      atactivePath: '', // 当前活动页面路径
      activeName: '', // 定义当前活动导航的名称
      parentName: '', // 定义当前活动导航父级导航名称
      activeTabName: 'fourth'

    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.demandId = window.sessionStorage.getItem('demandId')
    this.getBugType()
    this.getBugLevel()
    this.getUserListByDemand()
  },

  methods: {

    async submitForm (formName) {
      /**
       * @description: 新增bug方法
       */
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.bugForm.demandId = this.demandId
          this.bugForm.content = this.$refs.hahaha.content
        } else {
          console.log('error submit!!')
          return false
        }
      })
      const { data: res } = await this.$http.post('py/server/project/addBug', this.bugForm)

      if (!res.success) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.bugForm = ''
      this.goBack()
    },
    resetForm (formName) {
      /**
       * @description: 表单清空方法
       */
      this.$refs[formName].resetFields()
    },
    goBack () {
      /**
       * @description: 返回上一页
       */
      window.sessionStorage.setItem('tabName', this.activeTabName)
      this.$router.back(0)
    },
    async getBugType () {
      /**
       * @description: 获取bug类型
       */
      const { data: res } = await this.$http.get('py/server/project/getBugType')
      if (!res.success) return this.$message.error(res.msg)
      this.bugType = res.data
    },
    async getBugLevel () {
      /**
       * @description: 获取bug严重级别
       */
      const { data: res } = await this.$http.get('py/server/project/getBugLevel')
      if (!res.success) return this.$message.error(res.msg)
      this.bugLevel = res.data
    },
    async getUserListByDemand () {
      /**
       * @description: 获取需求所有负责人
       */
      const { data: res } = await this.$http.get('py/server/project/getUserListByDemand?id=' + this.demandId)

      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    getHander (item) {
      /**
       * @description: 监听负责人，变更后赋值
       */
      this.bugForm.handleCode = item.code
      this.bugForm.handleName = item.value
    }

  }
}
</script>

<style lang="less" scoped>
</style>
