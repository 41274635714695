<template>

  <el-card>

    <el-form :inline="true"
             :model="formInline"
             class="demo-form-inline">
      <el-form-item label="品牌">
        <el-select v-model="formInline.brand_type"
                   @change="setBrand($event)"
                   placeholder="选择品牌">
          <el-option v-for="item in dbList"
                     :key="item.key"
                     :label="item.value"
                     :value="item"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="监管">
        <el-select v-model="formInline.supervise"
                   placeholder="选择监管">
          <el-option v-for="supervise in superviseList"
                     :key=supervise.id
                     :label=supervise.name
                     :value=supervise.name></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="邮箱地址">
        <el-input v-model="formInline.email"
                  placeholder="邮箱地址"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData"
              stripe
              style="width: 100%">
      <el-table-column prop="subject"
                       label="subject"
                       width="180">
      </el-table-column>
      <el-table-column prop="template_invoke_name"
                       label="template_invoke_name"
                       width="180">
      </el-table-column>
      <el-table-column label="vars">
        <template slot-scope="scope">
          <div v-highlight="{ hWord: 'CODE|PASSWORD', word: scope.row.vars }">

          </div>

        </template>
      </el-table-column>

    </el-table>

  </el-card>

</template>

<script>


export default {


  data () {
    return {
      formInline: {
        email: '',
        brand_type: 'au',
        supervise: 'VFSC2',
        brand_id: '00'

      },
      superviseList: [],
      tableData: [],
      dbList: [],

      queryData: {
        brand_id: '',
        brand_type: ''
      }


    }
  },

  created () {
    this.getBrand()
    this.onSubmit()
  },
  methods: {
    async getSupervise () {

      // this.queryData.id = id
      // this.qureyData.brand_type = type
      // console.log(thos.queryData)
      const { data: res } = await this.$http.post('py/sever/env/getSupervise', this.formInline)

      if (res.success == true) {
        this.superviseList = res.data
      }

      else { this.$message(res.msg) }

    },
    async onSubmit () {
      const { data: res } = await this.$http.post('py/sever/env/searchEmail', this.formInline)
      if (res.success) {

        this.tableData = res.data.records
        console.log(this.tableData)

      } else {
        this.$message.error(res.msg)
      }
    },
    async getBrand () {
      const { data: res } = await this.$http.get('py/sever/env/getBrand')
      this.dbList = res.data

    },
    setBrand (item) {
      this.formInline = {
        email: '',
        brand_type: '',
        supervise: '',
        brand_id: ''

      },
        this.formInline.brand_id = item.key
      this.formInline.brand_type = item.value
      this.getSupervise(item.key, item.value)

    },

  }

}


</script>

<style>
</style>