<template>
        <el-form-item label="测试环境" prop="env">
            <el-autocomplete v-model="form.environment" :fetch-suggestions="querySearchAsync"
                placeholder="注：不填默认core环境" @select="handleSelect" clearable></el-autocomplete>
        </el-form-item>

</template>

<script>
import { Autocomplete } from 'element-ui';
import envObj from '../../../public/env.json';

export default {
    name: 'AdminEnvAutocomplete',
    components: {
        'el-autocomplete': Autocomplete
    },
    props: {
        form: {
            type: Object,
            required: true
        }

    },

    data() {
        const envKey = Object.keys(envObj);
        const envArr = envObj[envKey].map(item => ({ value: item }))
        return {
            envArr
        }
    },

    methods: {
        querySearchAsync(queryString, cb) {
            var restaurants = this.envArr;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
            cb(results);
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect(item) {
            console.log(item);
        },


    }

}
</script>

<style></style>