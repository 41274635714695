<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>
        <el-form :inline="true"
                 :model="pageQuery">
          <el-form-item>
            <el-button type="primary"
                       size="mini"
                       @click="getPageList">查询数据</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="setEnv(true, '')">新增环境</el-button>
          </el-form-item>
        </el-form>

        <template>
          <el-table :data="pageList"
                    height="580"
                    :header-cell-style="{ 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }"
                    border
                    style="width: 100%"
                    stripe>
            <el-table-column type="index"
                             label="序号"
                             width="55">
            </el-table-column>
            <el-table-column prop="code"
                             label="环境编码"
                             width="150">
            </el-table-column>
            <el-table-column prop="name"
                             label="环境名称"
                             width="150">
            </el-table-column>
            <el-table-column prop="redis"
                             label="缓存地址"
                             width="150">
            </el-table-column>
            <el-table-column prop="url"
                             label="请求地址"
                             width="150">
            </el-table-column>
            <el-table-column label="操作"
                             width="150">
              <template slot-scope="scope">
                <el-button type="warning"
                           size="mini"
                           @click="setEnv(false, scope.row)"
                           round>修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageQuery.current"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="pageQuery.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog title="环境信息"
               :visible.sync="envFlag"
               width="40%">
      <el-form label-width="70px">
        <el-form-item label="环境编码">
          <el-input v-model="envData.code"></el-input>
        </el-form-item>
        <el-form-item label="环境名称">
          <el-input v-model="envData.name"></el-input>
        </el-form-item>
        <el-form-item label="缓存地址">
          <el-input v-model="envData.redis"></el-input>
        </el-form-item>
        <el-form-item label="请求地址">
          <el-input v-model="envData.url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="envFlag = false">取 消</el-button>
        <el-button type="primary"
                   @click="addEnv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      parentName: '',
      activeName: '',
      envFlag: false,
      pageList: [],
      envData: {
        code: '',
        name: '',
        redis: '',
        url: '',
        id: ''
      },
      pageQuery: {
        current: 1,
        size: 10
      },
      total: '0'
    }
  },
  created () {
    this.getPageList()
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
  },
  methods: {
    async getPageList () {
      const { data: res } = await this.$http.post(
        'api/server/test/data/getEnvPage',
        this.pageQuery
      )
      if (res.success) {
        this.pageList = res.data.records
        this.total = res.data.total
      }
    },
    async addEnv () {
      const { data: res } = await this.$http.post(
        'api/server/test/data/updateEnv',
        this.envData
      )
      if (res.success) {
        this.$message.success('添加成功')
        this.envFlag = false
        this.getPageList()
      } else {
        this.$message.error('添加失败')
      }
    },
    setEnv (flag, data) {
      this.envFlag = true
      if (flag) {
        this.envData.name = ''
        this.envData.code = ''
        this.envData.redis = ''
        this.envData.url = ''
        this.envData.id = ''
      } else {
        this.envData.name = data.name
        this.envData.code = data.code
        this.envData.redis = data.redis
        this.envData.url = data.url
        this.envData.id = data.id
      }
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage
      this.getPageList()
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent
      this.getPageList()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
