<template>
  <div class="login_container">
    <div class="logo_box">
      <img src="../assets/img/login_logo.af1ccbb.png"
           alt="" />
    </div>

    <div class="login_box">
      <h1 style="margin-left: 20px">登录</h1>
      <!-- <span class="goNavButton"
            @click="goNav">返回导航</span> -->
      <span class="goRegisterButton"
            @click="goRegister">账户注册</span>
      <el-form ref="loginForm"
               :model="loginForm"
               :rules="loginRules"
               label-width="80px"
               class="login_form">
        <!-- 用戶名 -->
        <el-form-item prop="userName"
                      label="用户名：">
          <el-input v-model="loginForm.userName"
                    prefix-icon="el-icon-s-custom"
                    placeholder="请输入用户名"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="passWord"
                      label="密码：">
          <el-input v-model="loginForm.passWord"
                    prefix-icon="el-icon-lock"
                    type="password"
                    placeholder="请输入密码"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary"
                     @click="login">登录</el-button>
          <el-button type="info"
                     @click="reSetLogin">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="账户注册"
               :visible.sync="userFormVisible"
               :close-on-click-modal="false"
               :show-close="false"
               width="450px">
      <el-form :model="registerForm">
        <el-form-item label="用户名："
                      prop="username"
                      :label-width="formLabelWidth">
          <el-input v-model="registerForm.username"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码："
                      prop="password"
                      :label-width="formLabelWidth">
          <el-input v-model="registerForm.password"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="jira账号："
                      prop="jira_name"
                      :label-width="formLabelWidth">
          <el-input v-model="registerForm.jira_name"
                    autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item label="jira口令："
                      prop="api_token"
                      :label-width="formLabelWidth">
          <el-input v-model="registerForm.api_token"
                    autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item label="职位"
                      :label-width="formLabelWidth">
          <el-select @change="setUserType"
                     v-model="registerForm.typeStr"
                     placeholder="请选择分类">
            <el-option v-for="item in userTypeList"
                       :key="item.key"
                       :label="item.value"
                       :value="item"></el-option>

          </el-select>
        </el-form-item>
        <el-link type="primary"
                 @click="test()"
                 style="margin-left: 50%;"
                 target="_blank">点我获取Jira口令教程</el-link>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeUserFormVisible">取 消</el-button>
        <el-button type="primary"
                   @click="addUser">注 册</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Jira口令教程"
               :visible.sync="helpVisible"
               width="450">
      <div>
        <div>
          <h3>第一步：登录jira账号，点击设置，选择账户设置</h3>
          <img src="../assets/img/step1.jpg"
               width="800px">
        </div>
        <div>
          <h3>第二步：点击安全性，选择创建管理API令牌</h3>
          <img src="../assets/img/step2.jpg"
               width="800px">
        </div>
        <div>
          <h3>第三步：输入标签（随意名字），点击创建</h3>
          <img src="../assets/img/step3.jpg"
               width="800px">
        </div>
        <div>
          <h3>第四步：点击复制按钮，复制API令牌</h3>
          <img src="../assets/img/step4.jpg"
               width="800px">
        </div>

      </div>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="helpVisible=false">取 消</el-button>

      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
      loginForm: {
        userName: '',
        passWord: '',
        token: ''
      },
      formLabelWidth: "100px",
      addTeamForm: {
        type: '', // 分组key
        typeStr: '' // 分组value
      },
      registerForm: {
        username: '',
        password: "",
        jira_name: "",
        api_token: "",
        type: '',
        typeStr: "",
        register: "yes"
      },

      userTypeList: [],
      userFormVisible: false,
      helpVisible: false,
      loginRules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 30, message: '长度在6到30之间', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    reSetLogin () {
      this.$refs.loginForm.resetFields()
    },
    handleResize () {
      this.fullWidth = document.documentElement.clientWidth
      this.fullHeight = document.documentElement.clientHeight
    },
    async getUserType () {
      const { data: res } = await this.$http.get('py/server/user/getUserType', '')

      this.userTypeList = res.data
    },
    test () {
      this.helpVisible = true
      // this.$router.push('/register')
    },
    setUserType (e) {
      this.registerForm.type = e.key
      this.registerForm.typeStr = e.value
    },
    async addUser () {
      const { data: res } = await this.$http.post('py/server/user/addUser', this.registerForm)
      if (res.success) {
        this.$message.success(res.msg)
        this.closeUserFormVisible()
      } else {
        this.$message.error(res.msg)

      }

    },

    login () {
      this.$refs.loginForm.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.post(
          'py/server/user/login',
          this.loginForm
        )
        if (res.data.success) {

          window.sessionStorage.setItem('token', res.data.data.token)
          window.sessionStorage.setItem('nickName', res.data.data.name)
          window.sessionStorage.setItem('accountCode', res.data.data.accountCode)
          window.sessionStorage.setItem('roleType', res.data.data.typeStr)
          this.$message.success('登录成功')
          this.$router.push('/home')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    keyDown (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    goNav () {
      this.$router.push('/navShow')
    },
    goRegister () {
      this.userFormVisible = true
      this.getUserType()

    },
    closeUserFormVisible () {
      this.userFormVisible = false
      this.registerForm = {
        username: '',
        password: "",
        jira_name: "",
        api_token: "",
        type: '',
        typeStr: "",
        register: "yes"
      }
    },
  },
  created () {
    window.addEventListener('resize', this.handleResize)

  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    window.addEventListener('keydown', this.keyDown)
  }
}
</script>

<style lang="less" scoped>
.login_container {
  position: relative;
  min-height: 100vh;
  background-image: linear-gradient(
      to right,
      rgba(58, 136, 254, 0.6) 5%,
      rgba(84, 132, 255, 0.3) 50%,
      rgba(84, 132, 255, 0)
    ),
    url(../assets/img/login_bg.b9d6ed3.jpg);
  background-size: cover;
  overflow-x: hidden;
}

.login_box {
  // 宽度
  width: 450px;
  //高度
  height: 300px;
  background-color: #fff;
  //倒角
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 5%;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
.logo_box {
  position: absolute;
  width: 300px;
  // height: 36px;
  // left: 69px;
  // top: 58px;
  img {
    width: 100%;
  }
}

.goNavButton {
  position: absolute;
  top: 10%;
  left: 70%;
  border: none;
  color: RGB(6, 108, 193);
}
.goNavButton:hover {
  cursor: pointer;
}
.goRegisterButton {
  position: absolute;
  top: 10%;
  left: 70%;
  border: none;
  color: RGB(6, 108, 193);
}
.goRegisterButton:hover {
  cursor: pointer;
}
</style>
