<!--我的项目-需要排期的需求列表页-->
<template>

  
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
  
      </el-breadcrumb>
      <el-card>
        <el-tabs v-model="tabName"
                 @tab-click="handleClick">
          <el-tab-pane label="进行中"
                       name="first">
            <search-component :key="timer"
                              :itemList="searchList"
                              :formData="searchData">
            </search-component>
  
            <div class="table_box">
  
              <el-table :data="demandList"
                        border
                        style="width: 96%;"
                        height="600"
                        align="center"
                        :row-class-name="tableRowClassName">
  
                <el-table-column label="序号"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.$index + 1 }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求编号"
                                 width="120px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.key }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求名称"
                                 width="">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.title }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="原型地址">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
  
                      <a :href="scope.row.link"
                         target="_blank"><el-tag size="medium">{{ scope.row.link }}</el-tag></a>
  
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="优先级"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.priority }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求状态"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.status }}</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="所属系统"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.systemName }}</span>
                    </div>
                  </template>
                </el-table-column> -->


  
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已完成"
                       name="second">
            <search-component :key="timer"
                              :itemList="searchListHistory"
                              :formData="searchData">
            </search-component>
  
            <el-table :data="demandListHistory"
                        border
                        style="width: 96%;"
                        height="600"
                        align="center"
                        :row-class-name="tableRowClassName">
  
                <el-table-column label="序号"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.$index + 1 }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求编号"
                                 width="120px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.key }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求名称"
                                 width="">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.title }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="原型地址">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
  
                      <a :href="scope.row.link"
                         target="_blank"><el-tag size="medium">{{ scope.row.link }}</el-tag></a>
  
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="优先级"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.priority }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求状态"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.status }}</span>
                    </div>
                  </template>
                </el-table-column>

            </el-table></el-tab-pane>
  
        </el-tabs>

      </el-card>
    </div>
  
  </template>
  
  <script>
  import SearchComponent from '../common/Serach.vue'
  export default {
    components: {
      SearchComponent
    },
    data () {
      return {
        // 当前活动页面路径
        atactivePath: '',
        // 定义当前活动导航的名称
        activeName: '',
        // 定义当前活动导航父级导航名称
        parentName: '',
        tabName: 'first',
        demand_Statistics: [
          {
  
            value: 36,
            title: '需求总数'
          },
          {
            value: 4154.564,
            title: '所用工时'
          },
          {
            value: 34,
            title: '已完成需求'
          },
          {
            value: 2,
            title: '进行中需求'
          }
        ],
        demandList: [],
        demandListHistory: [],
        weekFirst: '',
        weekEnd: '',
        dialogTableVisible: false,
        dialogFormVisible: false,

        pageQuery: {
          demandId: ""
        },
        searchData: {
          demandId: ""
        },

        formLabelWidth: '120px',
        searchList: [
          { label: '需求编号：', prop: 'demandId', type: 'input', change: this.getEvent },
          // { label: '需求名称：', prop: 'demandName', type: 'input', change: this.getEvent },
          // {
          //   label: '需求状态：',
          //   prop: 'status',
          //   type: 'select',
          //   multiple: true,
          //   options: [ // bug类型
          //     {
          //       label: '待CR',
          //       value: '02'
          //     },
          //     {
          //       label: '待提测',
          //       value: '03'
          //     },
          //     {
          //       label: '提测待通过',
          //       value: '04'
          //     },
          //     {
          //       label: '待测试',
          //       value: '05'
          //     },
          //     {
          //       label: '测试中',
          //       value: '06'
          //     },
          //     {
          //       label: '待上线',
          //       value: '07'
          //     }
          //     // ,{
          //     //   label: '已上线',
          //     //   value: '08'
          //     // }
          //   ]
  
          // },
          // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
          {
            type: 'button',
            btnList: [
              { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search(row) },
              { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() }
            ]
          }
        ],
        searchListHistory: [
          { label: '需求编号', prop: 'demandId', type: 'input', change: this.getEvent },
          // { label: '需求名称：', prop: 'demandName', type: 'input', change: this.getEvent },
  
          // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
          {
            type: 'button',
            btnList: [
              { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
              { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() }
            ]
          }
        ],
  
        timer: ''
  
      }
    },
  
    created () {
      this.activePath = window.sessionStorage.getItem('activePath')
      this.activeName = window.sessionStorage.getItem('activeName')
      this.parentName = window.sessionStorage.getItem('parentName')
      this.getMyActiveIssuesList()
    },
  
    methods: {
  
      tableRowClassName ({ row }) {
        if (row.is_Schedule === true) {
          return 'warning-row'
        } else if (row.is_Schedule === false) {
          return 'success-row'
        }
        return ''
      },

      async getMyActiveIssuesList () {
        const { data: res } = await this.$http.post('py/server/project/jira/getMyActiveIssues', this.searchData)
          if (!res.success) return this.$message.error(res.msg)
          this.demandList = res.data

      },
      async getMyHistoryIssuesList () {
        const { data: res } = await this.$http.post('py/server/project/jira/getMyHistoryIssues', this.searchData)
          if (!res.success) return this.$message.error(res.msg)
          this.demandListHistory = res.data
      },


      getEvent (res) {
    
  
      },
      search (res) {
        if (this.tabName === 'second') {
        
          this.getMyHistoryIssuesList()
        }
        if (this.tabName === 'first') {
          this.getMyActiveIssuesList()
        }
      },
      reset () {
        this.searchData = {
          demandId: '',

        }
        if (this.tabName === 'first') {
          this.timer = new Date().getTime()
          this.getMyActiveIssuesList()
        }
        if (this.tabName === 'second') {
          this.timer = new Date().getTime()
          this.getMyHistoryIssuesList()
        }
      },
      handleClick (tab) {
        this.tabName = tab.name
        this.pageQuery = {
          demand_id: '',
        }
        this.reset()
      }
    }
  
  }
  </script>
  
  <style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .button_box_left {
    position: absolute;
    top: 50%;
  }
  .button_box_right {
    position: absolute;
    top: 50%;
    right: 5%;
  }
  
  .add_button {
    margin-left: 1385px;
  }
  .el-table .warning-row {
    background: oldlace;
  }
  
  .el-table .success-row {
    background: #f0f9eb;
  }
  </style>
  