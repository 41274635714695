<!--公共需求池-->
<template>
  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--顶部数据展示-->

    <el-card>
      <template>

        <el-tabs v-model="tabName"
                 @tab-click="handleClick">

          <el-tab-pane label="进行中"
                       name="second">
            <search-component :key="timer"
                              :itemList="searchList"
                              :formData="searchData">
            </search-component>
            <div>

              <div class="table_box">

                <el-table :data="activeDemandList"
                          border
                          style="width: 96%;"
                          height="640"
                          align="center">

                  <el-table-column label="序号"
                                   width="60px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.$index + 1 }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求编号"
                                   width="120px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.row.id }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求名称"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <div class="text-ellipsis"
                             :title="scope.row.demandName">{{ scope.row.demandName }}</div>

                        <!-- <span size="medium"
                            :title="scope.row.demandName ">{{ scope.row.demandName }}</span> -->
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="原型地址"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <a :href="scope.row.demandUrl"
                           target="_blank">
                          <el-tag size="medium">{{ scope.row.demandUrl }}</el-tag>
                        </a>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求状态"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.demandStatusStr }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="所属系统"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">

                        <span size="medium">{{ scope.row.systemName }}</span>

                      </div>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="创建人"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.creatorName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="参与人员"
                                   width="160px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.participationName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="创建时间"
                                   width="160px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.createTime }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="操作"
                                   width="200px">
                    <template slot-scope="scope">
                      <el-button size="mini"
                                 @click="demandDetail(scope.row.id)">查看</el-button>
                      <el-button size="mini"
                                 type="primary"
                                 @click="onenVisible(scope.row)">指派人员</el-button>

                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="已完成"
                       name="third">
            <div class="table_box">
              <search-component :key="timer"
                                :itemList="searchListHistory"
                                :formData="searchData">
              </search-component>
              <el-table :data="historyDemandList"
                        border
                        style="width: 96%;"
                        height="640"
                        align="center">

                <el-table-column label="序号"
                                 width="60px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.$index + 1 }} </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求编号"
                                 width="120px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.row.id }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求名称"
                                 width="">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <div class="text-ellipsis"
                           :title="scope.row.demandName">{{ scope.row.demandName }}</div>

                      <!-- <span size="medium"
                            :title="scope.row.demandName ">{{ scope.row.demandName }}</span> -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="原型地址"
                                 width="160px">

                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <a :href="scope.row.demandUrl"
                         target="_blank">
                        <el-tag size="medium">{{ scope.row.demandUrl }}</el-tag>
                      </a>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求状态"
                                 width="100px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <div v-if="scope.row.demandStatus === '08'">
                        <el-tag type="success">{{ scope.row.demandStatusStr }}</el-tag>
                      </div>
                      <div v-else>
                        <el-tag type="danger">{{ scope.row.demandStatusStr }}</el-tag>
                      </div>

                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="所属系统"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">

                      <span size="medium">{{ scope.row.systemName }}</span>

                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="创建人"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.creatorName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="参与人员"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.participationName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="创建时间"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.createTime }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="提测日期"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.testDate }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="上线日期"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.onlineDate }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="工时"
                                 width="100">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.workHours + '天/人'}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作"
                                 width="80">
                  <template slot-scope="scope">
                    <el-button size="mini"
                               @click="demandDetail(scope.row.id)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </el-tab-pane>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="pageQuery.current"
                         :page-sizes="[10, 20, 30, 40]"
                         :page-size="pageQuery.size"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-tabs>

      </template>

    </el-card>
    <el-dialog title="指派人员"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="450px">
      <el-form :model="form">

        <el-form-item label="选择人员："
                      :label-width="formLabelWidth">
          <el-select v-model="form.participationCode"
                     multiple
                     filterable
                     placeholder="请分配人员">
            <el-option v-for="user in userList"
                       :key="user.code"
                       :label="user.value"
                       :value="user.code"></el-option>

          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelVisible">取 消</el-button>
        <el-button type="primary"
                   @click="addParticipation">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import SearchComponent from '../../common/Serach.vue'
export default {
  components: {
    SearchComponent
  },
  data () {
    return {
      deleteVisible: false,
      form: {
        id: '',
        participationCode: []

      },
      formInline: {
        version: '',
        systemName: '',
        demandName: ''

      },

      pageQuery: {
        current: 1,
        size: 10,
        username: '',
        nickname: ''
      },
      total: 0,

      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',

      activeDemandList: [], // 已发起列表
      historyDemandList: [], // 已上线需求列表
      formLabelWidth: '120px',

      demandId: '', // 需求id 用于发起需求
      dialogFormVisible: false,
      tabName: 'second',
      userList: [],
      searchList: [
        { label: '版本号：', prop: 'version', type: 'input', change: this.getEvent },
        { label: '需求名称：', prop: 'demandName', type: 'input', change: this.getEvent },
        {
          label: '需求状态：',
          prop: 'status',
          type: 'select',
          multiple: true,
          options: [ // bug类型
            {
              label: '待CR',
              value: '02'
            },
            {
              label: '待提测',
              value: '03'
            },
            {
              label: '提测待通过',
              value: '04'
            },
            {
              label: '待测试',
              value: '05'
            },
            {
              label: '测试中',
              value: '06'
            },
            {
              label: '待上线',
              value: '07'
            }
            // {
            //   label: '已上线',
            //   value: '08'
            // }
          ]

        },
        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() }
          ]
        }
      ],
      searchListHistory: [
        { label: '版本号：', prop: 'version', type: 'input', change: this.getEvent },
        { label: '需求名称：', prop: 'demandName', type: 'input', change: this.getEvent },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() }
          ]
        }
      ],
      searchData: {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10,
        username: '',
        nickname: ''
      },
      timer: ''

    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.getUserList()
    this.getActiveDemandList()
  },

  methods: {
    handleClick (tab) {
      this.reset()

      this.tabName = tab.name
      if (this.tabName === 'second') {
        this.getActiveDemandList()
      } else if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    demandDetail (id) {
      window.sessionStorage.setItem('tabName', 'first')
      window.sessionStorage.setItem('demandId', id)
      this.$router.push('/demandDetail')
    },

    async getActiveDemandList () { // 获取已发起需求列表页方法
      const { data: res } = await this.$http.post('py/server/project/getDemandInfoVOPage', this.searchData)
      if (!res.success) return this.$message.error(res.msg)
      this.activeDemandList = res.data.records

      this.total = res.data.total
    },
    async getHistoryDemandList () {
      const { data: res } = await this.$http.post('py/server/project/getOnlineDemandInfoVOPage', this.searchData)
      if (!res.success) return this.$message.error(res.msg)
      this.historyDemandList = res.data.records
      this.total = res.data.total
    },
    handleSizeChange (newPage) {
      this.searchData.size = newPage
      if (this.tabName === 'second') {
        this.getActiveDemandList()
      }
      if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    handleCurrentChange (newCurrent) {
      this.searchData.current = newCurrent
      if (this.tabName === 'second') {
        this.getActiveDemandList()
      }
      if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },

    async getUserList () {
      /**
       * @description： 获取公司所有人员列表
       *
       */
      const { data: res } = await this.$http.get('py/server/user/getUserList')
      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    onenVisible (row) {
      this.form.participationCode = row.participationCode
      this.form.id = row.id
      this.dialogFormVisible = true
    },
    cancelVisible () {
      this.dialogFormVisible = false
      this.form = {
        id: '',
        participationCode: []

      }
    },
    async addParticipation () {
      const { data: res } = await this.$http.post('py/server/project/addParticipation', this.form)
      if (!res.success) return this.$message.error(res.msg)
      this.dialogFormVisible = false
      this.$message.success('添加成功')
      this.getActiveDemandList()

      this.form = {
        id: '',
        participationCode: []

      }
    },
    getEvent (res) {

    },
    search () {
      if (this.tabName === 'second') {
        this.getActiveDemandList()
      }
      if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    reset () {
      this.searchData = {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10

      }
      if (this.tabName === 'third') {
        this.timer = new Date().getTime()
        this.getHistoryDemandList()
      }
      if (this.tabName === 'second') {
        this.timer = new Date().getTime()
        this.getActiveDemandList()
      }
    }

  }
}

</script>

<style>
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
