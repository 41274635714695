<!--数据工具-短信数据查询-->
<template>
  <div>

    <el-table :data="list"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
              border
              style="width: 100%"
              stripe>
      <el-table-column type="index"
                       label="序号"
                       width="50"> </el-table-column>
      <el-table-column prop="mobile"
                       label="手机号"
                       width="120">
      </el-table-column>
      <el-table-column prop="content"
                       label="内容"
                       width="705">
      </el-table-column>
      <el-table-column prop="envStr"
                       label="环境"
                       width="100">
      </el-table-column>
      <el-table-column prop="channel"
                       label="通道"
                       width="100">
      </el-table-column>

      <el-table-column prop="crtDate"
                       label="创建日期"
                       width="160">
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="pageQuery.current"
                   :page-sizes="[10, 20, 30, 40]"
                   :page-size="pageQuery.size"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total">
    </el-pagination>
  </div>

</template>

<script>
export default {
  props: ['list'],

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
  },
  data () {
    return {
      pageList: [],
      total: 0,
      pageQuery: {
        current: 1,
        size: 10,
        mobile: '',
        env: '',
        envStr: ''
      },

      env: [],
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      dialogVisible: false
    }
  },
  methods: {
    async getPageList () {
      const { data: res } = await this.$http.post(
        'api/DangBan/SMS/getPageList',
        this.pageQuery
      )
      if (res.success) {
        this.pageList = res.data.records
        this.total = res.data.total
      }
    },
    async getEnv () {
      const { data: res } = await this.$http.get(
        'api/server/test/data/getDataSource'
      )
      this.env = res.data
    },
    setQuery (e) {
      this.pageQuery.env = e.key
      this.pageQuery.envStr = e.value
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage
      this.getPageList()
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent
      this.getPageList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
