<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>

      <el-tabs v-model="tabName"
               @tab-click="handleClick">
        <el-tab-pane label="预约中"
                     name="first">
          <search-component :key="timer"
                            :itemList="searchList"
                            :formData="searchData">
          </search-component>

          <el-table :data="mettingPageListNow"
                    border
                    style="width: 90%"
                    height="600">
            <el-table-column type="index"
                             label="序号"
                             width="80">
            </el-table-column>
            <el-table-column prop="title"
                             label="会议标题">
            </el-table-column>
            <el-table-column prop="address"
                             label="会议室名称"
                             width="120">
            </el-table-column>
            <el-table-column prop="date"
                             label="会议日期"
                             width="120">
            </el-table-column>
            <el-table-column prop="circleTime"
                             label="会议时间"
                             width="120">
            </el-table-column>
            <el-table-column prop="create_name"
                             label="发起人"
                             width="80">
            </el-table-column>
            <el-table-column prop="statusStr"
                             label="会议状态"
                             width="80">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini"
                           @click="openDialog('get', scope.row)">查看</el-button>
                <el-button v-if="scope.row.status == '00' && scope.row.create_code == accountCode"
                           size="mini"
                           @click="openDialog('edit',scope.row)"
                           v-show="true">编辑</el-button>

                <el-button size="mini"
                           v-if="scope.row.status == '00' && scope.row.create_code == accountCode"
                           type="success"
                           @click="updateMeeting('start', scope.row)">开始会议</el-button>
                <el-button v-if="scope.row.status == '01' && scope.row.create_code == accountCode"
                           size="mini"
                           type="info"
                           @click="updateMeeting('end', scope.row)">结束会议</el-button>
                <el-button v-if="scope.row.status == '00' && scope.row.create_code == accountCode"
                           size="mini"
                           type="danger"
                           @click="deleteMetting(scope.$index, scope.row)">取消会议</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已完成"
                     name="second">
          <search-component :key="timer"
                            :itemList="searchListHistory"
                            :formData="searchData">
          </search-component>
          <el-table :data="mettingPageListHistory"
                    border
                    style="width: 80%">
            <el-table-column type="index"
                             label="序号"
                             width="80">
            </el-table-column>
            <el-table-column prop="title"
                             label="会议标题">
            </el-table-column>
            <el-table-column prop="address"
                             label="会议室名称"
                             width="120">
            </el-table-column>
            <el-table-column prop="date"
                             label="会议日期"
                             width="120">
            </el-table-column>
            <el-table-column prop="circleTime"
                             label="会议时间"
                             width="120">
            </el-table-column>

            <el-table-column prop="create_name"
                             label="发起人"
                             width="80">
            </el-table-column>
            <el-table-column prop="statusStr"
                             label="会议状态"
                             width="80">
            </el-table-column>
            <el-table-column label="操作"
                             width="100">
              <template slot-scope="scope">
                <el-button size="mini"
                           @click="openDialog('get', scope.row)">查看</el-button>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="searchData.current"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="searchData.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </el-tabs>
    </el-card>
    <el-dialog :title="visibleTitle"
               :visible.sync="mettingVisible"
               width="40%"
               :close-on-click-modal="false">
      <el-form ref="mettingFormRef"
               :model="visibleForm"
               :rules="addMeetingFormRule">
        <el-form-item label="会议标题："
                      prop="title"
                      :label-width="formLabelWidth">
          <el-input v-model="visibleForm.title"
                    :disabled="is_disabled"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="会议室："
                      prop="address"
                      :label-width="formLabelWidth">
          <el-select v-model="visibleForm.address"
                     :disabled="is_disabled"
                     @change="getTimesByDate"
                     placeholder="请选择会议室">
            <el-option v-for="item in roomList"
                       :key="item.key"
                       :label="item.value"
                       :value="item.key"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="会议日期："
                      prop="date"
                      :label-width="formLabelWidth">
          <div class="block">
            <el-date-picker v-model="visibleForm.date"
                            :disabled="is_disabled"
                            type="date"
                            value-format="yyyy-MM-dd"
                            @change="getTimesByDate"
                            placeholder="选择日期">
            </el-date-picker>

          </div>

        </el-form-item>
        <el-form-item label="会议时间："
                      prop="times"
                      :label-width="formLabelWidth">

          <el-checkbox-group v-model="visibleForm.times"
                             :disabled="is_disabled"
                             size="medium"
                             :fill="checkbox_color">

            <el-checkbox-button v-for="time in mettingTimes"
                                :key="time.key"
                                :border="true"
                                :disabled="!timeKeys.includes(time.key) "
                                style="margin-right: 20px;"
                                :label="time.key">{{ time.value }}
            </el-checkbox-button>

          </el-checkbox-group>

        </el-form-item>
        <el-form-item label="参会人员："
                      prop="attendCode"
                      :label-width="formLabelWidth">
          <el-select v-model="visibleForm.attendCode"
                     :disabled="is_disabled"
                     filterable
                     multiple
                     placeholder="请选择参会人员">
            <el-option v-for="user in userList"
                       :key="user.code"
                       :label="user.value"
                       :value="user.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button v-if="visibleType === 'add'"
                   type="primary"
                   @click="addMeeting">确 定</el-button>
        <el-button v-if="visibleType === 'edit'"
                   type="primary"
                   @click="updateMeeting('edit', visibleForm)">编 辑</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import SearchComponent from '../common/Serach.vue'

export default {

  components: {
    SearchComponent
  },
  data () {
    return {
      mettingTimes: [
        { key: '1', value: '08:30-09:30' },
        { key: '2', value: '09:30-10:30' },
        { key: '3', value: '10:30-11:30' },
        { key: '4', value: '13:30-14:30' },
        { key: '5', value: '14:30-15:30' },
        { key: '6', value: '15:30-16:30' },
        { key: '7', value: '16:30-17:30' },
        { key: '8', value: '17:30-18:30' }

      ],
      checkbox_color: '#90ee90',
      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称

      weekDates: {},
      WorkDateForm: {
        flag: null, // true 查上一周， false 查下一周
        num: 7,
        workDate: ''
      },
      formLabelWidth: '120px',
      visibleForm: {},
      addMeetingForm: { // 添加会议弹窗表单
        address: '', // 会议室名称
        title: '', // 会议标题
        date: '', // 会议日期
        attendCode: [], // 参会人员
        times: [] // 开始时间和结束时间

      },

      timeKeys: '',
      addMeetingFormRule: {
        address: [
          { required: true, message: '请选择会议室', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入会议标题', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择会议日期', trigger: 'blur' }
        ],
        times: [
          { required: true, message: '请选择会议时间', trigger: 'blur' }
        ]

      },
      roomList: [], // 会议室列表页
      userList: [], // 参与人员列表页
      tabName: 'first',
      weekFirst: '',
      weekEnd: '',
      mettingVisible: false,
      checkboxGroup: ['8:30-9:30'],

      mettingPageListNow: [], // 已预约的会议室列表
      mettingPageListHistory: [], // 已完成的会议室列表
      visibleTitle: '',
      visibleType: '', // 打开的窗口类型
      is_disabled: false,
      searchList: [

        { label: '会议标题：', prop: 'title', type: 'input', change: this.getEvent },
        { label: '会议室名称：', prop: 'address', type: 'input', change: this.getEvent },
        {
          label: '会议状态：',
          prop: 'status',
          type: 'select',
          // multiple: true,
          options: [ // bug类型
            {
              label: '待开始',
              value: '00'
            },
            {
              label: '会议中',
              value: '01'
            }
          ]
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() },
            { type: 'primary', label: '会议预约', size: 'mini', icon: 'el-icon-plus', handle: row => this.openDialog('add') }
          ]
        }
      ],
      searchListHistory: [

        { label: '会议标题：', prop: 'title', type: 'input', change: this.getEvent },
        { label: '会议室名称：', prop: 'address', type: 'input', change: this.getEvent },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() }

          ]
        }
      ],
      searchData: { // 添加会议弹窗表单
        address: '', // 会议室名称
        title: '', // 会议标题
        date: '', // 会议日期
        status: '', // 会议状态
        start: '', // 开始时间
        end: '', // 结束时间
        create_name: '', // 会议发起人
        current: 1,
        size: 10

      },
      timer: '',
      total: 0,
      accountCode: '',
      is_show_time: true

    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.accountCode = window.sessionStorage.getItem('accountCode')

    this.getMettingPageListNow()

  },

  methods: {

    openDialog (type, row) {

      this.visibleType = type
      if (this.visibleType === 'add') {
        this.getRoomList()
        this.getUserList()
        this.visibleTitle = '新增会议'
        this.visibleForm = this.addMeetingForm
        this.mettingVisible = true
      } else if (this.visibleType === 'edit') {
        this.visibleTitle = '会议编辑'
        this.getRoomList()
        this.getUserList()
        this.visibleForm = row
        this.getTimesByDate()
        this.mettingVisible = true
      } else {
        this.visibleTitle = '会议信息'
        this.visibleForm = row
        // this.getTimesByDate()
        this.is_disabled = true
        this.mettingVisible = true
      }
    },
    closeDialog () {
      this.mettingVisible = false
      this.is_disabled = false
      this.$refs.mettingFormRef.resetFields()
      this.getMettingPageListNow()
    },
    async addMeeting () {
      /**
       * @description： 新增会议接口
       *
       */

      this.$refs.mettingFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('py/server/project/addConference', this.visibleForm)
        if (!res.success) return this.$message.error(res.msg)
        this.$message.success('预约成功')
        this.mettingVisible = false

        this.visibleForm = {
          address: '', // 会议室名称
          title: '', // 会议标题
          date: '', // 会议日期
          attendCode: [], // 参会人员
          times: [] // 开始时间和结束时间
        }
        this.addMeetingForm = { // 添加会议弹窗表单
          address: '', // 会议室名称
          title: '', // 会议标题
          date: '', // 会议日期
          attendCode: [], // 参会人员
          times: [] // 开始时间和结束时间

        }
        this.getMettingPageListNow()
      })
    },
    async updateMeeting (status, row) {
      /**
       * @description： 修改会议接口
       *
       */
      if (status === 'edit') {
        const { data: res } = await this.$http.post('py/server/project/updateConference', row)
        if (!res.success) return this.$message.error(res.msg)
        this.$message.success('修改成功')
      }
      if (status === 'start') {
        row.status = '01'

        const { data: res } = await this.$http.post('py/server/project/updateConference', row)
        if (!res.success) return this.$message.error(res.msg)
        this.$message.success('会议已开始')
      }
      if (status === 'end') {
        row.status = '02'

        const { data: res } = await this.$http.post('py/server/project/updateConference', row)
        if (!res.success) return this.$message.error(res.msg)
        this.$message.success('会议已结束')
      }

      this.mettingVisible = false
      this.visibleForm = {
        address: '', // 会议室名称
        title: '', // 会议标题
        date: '', // 会议日期
        attendCode: [], // 参会人员
        times: [] // 开始时间和结束时间
      }
      this.getMettingPageListNow()
    },

    async getTimesByDate () {
      /**
       * @description： 根据日期和会议室，获取当天可用时间段
       * @param： timeKeys: 获取当天可用时间段的key，用于转化成value
       *TIME_8("1","08:30-09:30"),
       *  TIME_9("2","09:30-10:30")
       *  TIME_10("3","10:30-11:30")
       *  TIME_13("4","13:30-14:30")
       *  TIME_14("5","14:30-15:30")
       *  TIME_15("6","15:30-16:30")
       *  TIME_16("7","16:30-17:30")
       *  TIME_17("8","17:30-18:30")
       */
      const { data: res } = await this.$http.get('py/server/project/getConferenceByDate?date=' + this.visibleForm.date + '&address=' + this.visibleForm.address)
      // this.checkbox_color = ''
      if (!res.success) return this.$message.error(res.msg)
      this.timeKeys = res.data
    },
    async getUserList () {
      /**
       * @description： 获取公司所有人员列表
       *
       */
      const { data: res } = await this.$http.get('py/server/user/getUserList')
      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    async getMettingPageListNow () {
      /**
       * @description： 获取已预约的会议列表页
       * @return {Array} 预约会议列表
       */

      const { data: res } = await this.$http.post('py/server/project/getConferencePageListNow', this.searchData)
      if (!res.success) return this.$message.error(res.msg)
      this.mettingPageListNow = res.data.records
      this.total = res.data.total
      for (const i in this.mettingPageListNow) { this.mettingPageListNow[i].circleTime = this.mettingPageListNow[i].start + '-' + this.mettingPageListNow[i].end }
    },
    async getMettingPageListHistory () {
      /**
       * @description： 获取已完成的会议列表页
       * @return {Array} 预约会议列表
       */

      const { data: res } = await this.$http.post('py/server/project/getConferencePageListHistory', this.searchData)
      if (!res.success) return this.$message.error(res.msg)
      this.mettingPageListHistory = res.data.records
      this.total = res.data.total
      for (const i in this.mettingPageListHistory) { this.mettingPageListHistory[i].circleTime = this.mettingPageListHistory[i].start + ' - ' + this.mettingPageListHistory[i].end }
    },

    async getRoomList () {
      /**
       * @description : 获取会议室
       */
      const { data: res } = await this.$http.get('py/server/project/getConferenceAddress')
      if (!res.success) return this.$message.error(res.msg)
      this.roomList = res.data
    },
    async getMettingDetail (id, status) {
      /**
       * @description : 获取会议详细信息
       * @param {string} id ：会议id
       */
      const { data: res } = await this.$http.get('py/server/project/getConferenceDetail?id=' + id)
      if (!res.success) return this.$message.error(res.msg)
      this.visibleForm = res.data

    },
    handleClick (tab) {
      this.reset()
      if (tab.name === 'first') {
        this.getMettingPageListNow()
      }
      if (tab.name === 'second') {
        this.getMettingPageListHistory()
      }
    },
    handleSizeChange (newPage) {
      /**
       * @description：用于分页
       */
      this.searchData.size = newPage
      if (this.tabName === 'second') {
        this.getMettingPageListHistory()
      }
      if (this.tabName === 'first') {
        this.getMettingPageListNow()
      }
    },
    handleCurrentChange (newCurrent) {
      /**
       * @description：用于分页
       */
      this.searchData.current = newCurrent
      if (this.tabName === 'second') {
        this.getMettingPageListHistory()
      }
      if (this.tabName === 'first') {
        this.getMettingPageListNow()
      }
    },

    getEvent (res) {
      console.log(this.searchData)
    },
    search () {
      if (this.tabName === 'second') {
        this.getMettingPageListHistory()
      }
      if (this.tabName === 'first') {
        this.getMettingPageListNow()
      }
    },
    reset () {
      this.searchData = { // 添加会议弹窗表单
        address: '', // 会议室名称
        title: '', // 会议标题
        date: '', // 会议日期
        status: '', // 会议状态
        start: '', // 开始时间
        end: '', // 结束时间
        create_name: '', // 会议发起人
        current: 1,
        size: 10

      }
      // this.timer = new Date().getTime()
      // if (this.tabName === 'second') {
      //   this.getMettingPageListHistory()
      // }
      // if (this.tabName === 'first') {
      //   this.getMettingPageListNow()
      // }
    },


  }
}

</script>

<style lang="less" scoped>
::v-deep .el-checkbox-button--medium .el-checkbox-button__inner {
  padding: 10px 20px;
  font-size: 14px;

  border: 1px solid rgb(216, 209, 209);
}
</style>
