
<template>
  <div>
    <el-container class="home-container">
      <!--头部区域-->
      <el-header>
        <div>
          <img src="../assets/img/login_logo.af1ccbb.png"
               alt="">
          <span style="">上通办公</span>
        </div>
        <a href="/login"
           style="color:#fff !important">登录</a>
      </el-header>
      <el-card style="height:845px; overflow: scroll;width: 100%;">
        <div class="el-row">

          <div class="common-panel el-col el-col-24"
               v-for="item in groupList"
               :key="item.id"
               v-show="item.children ? true : false">
            <div class="common-panel-title el-row commonList"
                 :style="{backgroundColor: item.colour, borderColor: item.colour }"
                 style="text-align: left;">

              <span>{{ item.name }}</span>
            </div>
            <div class="common-panel-item el-col el-col-24 el-col-xs-24"
                 style="padding-left: 5px; padding-right: 5; padding-top: 10px; padding-bottom: 10px;">
              <el-row :gutter="20">
                <el-col :span="4"
                        v-for="urlData in item.children"
                        :key="urlData.id">
                  <div>
                    <a :href=urlData.url
                       target="_blank">
                      <el-card class="cardStyle"
                               shadow="hover"
                               style="background-color: #eaedf1;">
                        <div style=" margin-left: -8px;margin-top: -6px;">

                          <el-image :src="urlData.imgPath ? urlData.imgPath : require('@/assets/img/default_img.jpg')"></el-image>
                        </div>

                        <div style="margin-top: -20px;margin-left: 20px;">
                          <span>{{ urlData.name }}</span>

                        </div>

                      </el-card>
                    </a>
                    <div style="height: 8px;"></div>
                  </div>

                </el-col>
              </el-row>

            </div>

          </div>
        </div>
      </el-card>
    </el-container>
  </div>
</template>

<script>
export default {

  data () {
    return {
      groupList: [],
      childrenList: [],
      default_img: './11.jpg'

    }
  },

  created () {
    this.getGroupList()

  },

  methods: {
    async getGroupList () {
      const { data: res } = await this.$http.post('/py/server/test/data/getHomeNavigation')

      this.groupList = res.data
    },



  }
}
</script>

<style lang="less" scoped>
.common-panel {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  margin-bottom: 20px;
}

div {
  display: block;
}
.el-image {
  width: 20px;
  position: relative;
}

.common-panel .common-panel-title {
  padding-left: 20px;
  line-height: 40px;
  margin-left: 1px;
}
.commonList {
  background-color: #59e00b;
  color: #fff;
}
.common {
  margin-bottom: 20px;
}
.el-card__body {
  width: 100%;
  position: relative;
  top: -50%;
  right: 9%;
}
.el-card {
  width: 180px;
  height: 50px;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  min-width: 150px;
  min-height: 30px;
  margin-right: 20px;
  transition: all 0.5s;
}
.cardStyle.el-card:hover {
  background-color: #409eff !important;
}

a {
  text-decoration: none !important;
  color: #000000 !important;
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

.banner {
  text-align: center;

  .banner-image {
    width: 100%;
    height: auto;
    max-width: 320px;
    max-height: 180px;
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  img {
    height: 30px;
    align-items: center;
  }
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

img {
  border-style: none;
}
</style>
