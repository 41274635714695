<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card>

    <el-form ref="form" :model="form" label-width="80px" :inline="true" >

  <el-form-item label="选择人员">
    <el-select v-model="form.name" placeholder="请选择查看对象" filterable clearable  >
      <el-option v-for="item in userList" :key="item.code" :label="item.value" :value="item.code"></el-option>

    </el-select>
  </el-form-item>
  <el-form-item label="">
    <el-col :span="11">
      <el-date-picker value-format="yyyy-MM"
          v-model="form .month"
          type="month"
          placeholder="选择月">
        </el-date-picker>
    </el-col>

  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="getIssueByMonth">查询</el-button>
  </el-form-item>
</el-form>
      </el-card>
      <div class="table_box">
  
  <el-table :data="demandList"
            border

            height="600"
            align="center"
            :row-class-name="tableRowClassName">

    <el-table-column label="序号"
                     width="80px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span>{{ scope.$index + 1 }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="需求编号"
                     width="120px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.key }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="需求名称"
                     width="">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.title }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="原型地址">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">

          <a :href="scope.row.link"
             target="_blank"><el-tag size="medium">{{ scope.row.link }}</el-tag></a>

        </div>
      </template>
    </el-table-column>
    <el-table-column label="QA Start Date"
                     width="150px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.start }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="QA Due Date"
                     width="150px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.actualEnd }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="时长/天"
                     width="150px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.days }}天（工作日）</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="需求状态"
                     width="150px">
      <template slot-scope="scope">
        <div slot="reference"
             class="name-wrapper">
          <span size="medium">{{ scope.row.statusStr }}</span>
        </div>
      </template>
    </el-table-column>




  </el-table>
</div>
    </div>

  </template>
  
  <script >

  

  
  export default {

  
    data () {
      return {
        demandList: [],
        userList: [],
        value: "",
        form: {
          name: "",
          month: '',
          demandId: ""
        }

      }
    },
  
    created () {
      

    },
    mounted(){
      this.activePath = window.sessionStorage.getItem('activePath')
      this.activeName = window.sessionStorage.getItem('activeName')
      this.parentName = window.sessionStorage.getItem('parentName')
      this.myAccountName = window.sessionStorage.getItem('nickName')
      this.nowtime()
      this.getUserList()
      this.getIssueByMonth()
 
    },
  
    methods: {
      nowtime() {
 
        let nowDate = new Date();
        let date = {

          // 获取当前年份
          year: nowDate.getFullYear(),
          //获取当前月份
          month: nowDate.getMonth() + 1,
          //获取当前日期
          date: nowDate.getDate(),
        };
          
          //拼接
        this.form.month = date.year + "-" + date.month
        this.form.name = window.sessionStorage.getItem('accountCode')

        },
      async getUserList () {
      /**
       * @description： 获取公司所有人员列表
       *
       */
        const { data: res } = await this.$http.get('py/server/user/getUserList')
        if (!res.success) return this.$message.error(res.msg)
        this.userList = res.data
        console.log(this.userList)
    },
    
    async getIssueByMonth () {
        const { data: res } = await this.$http.post('py/server/project/jira/getIssueByMonth', this.form)
          if (!res.success) return this.$message.error(res.msg)
          this.demandList = res.data

      },
  
    }
  
  }
  </script>
  
  <style lang="less" scoped>
  
  
  </style>
  