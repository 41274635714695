<template>
  <div>
    <h3>第一步：登录jira账号，点击设置，选择账户设置</h3>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>