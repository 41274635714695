<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区域 -->

    <el-card>
      <div>

        <el-form inline
                 :model="pageQuery">
          <el-form-item prop="username"
                        label="账户"> <el-input v-model="pageQuery.username"></el-input> </el-form-item>
          <!-- <el-form-item prop="nickname"
                        label="姓名"> <el-input v-model="pageQuery.nickname"></el-input> </el-form-item>
          <el-form-item> -->
          <el-button type="primary"
                     @click="getPageList">查询数据</el-button>
          <!-- <el-button type="primary"
                       @click="getLDAPUser">同步数据</el-button> -->
          <el-button type="primary"
                     @click="openUserVisible">新增用户</el-button>

          </el-form-item>
        </el-form>

        <template>

          <el-table :data="pageList"
                    border
                    style="width: 100%"
                    stripe>
            <el-table-column type="index"
                             width="50"> </el-table-column>
            <el-table-column prop="username"
                             label="账户"
                             width="240"> </el-table-column>
            <!-- <el-table-column prop="nickname"
                             label="姓名"
                             width="180"> </el-table-column> -->
            <el-table-column prop="typeStr"
                             label="分组"
                             width="240"> </el-table-column>
            <el-table-column prop="jira_name"
                             label="jira账号"
                             width="240"> </el-table-column>
            <el-table-column prop="create_time"
                             label="创建时间"
                             width="180"> </el-table-column>
            <el-table-column label="是否启用"
                             width="120">
              <template slot-scope="scope">
                <el-popover trigger="hover"
                            placement="top">

                  <el-tag v-if="scope.row.isUse === 'Y' || scope.row.isUse === null"
                          size="medium">{{ scope.row.isUseStr }}</el-tag>
                  <el-tag v-else
                          size="medium"
                          type="danger">{{ scope.row.isUseStr }}</el-tag>

                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">

                <el-button type="success"
                           size="mini"
                           @click="isUse(scope.row, 'Y')">启用</el-button>

                <el-button type="warning"
                           size="mini"
                           @click="isUse(scope.row, 'N')">禁用</el-button>

                <el-button type="primary"
                           size="mini"
                           @click="openDialogVisible(scope.row)">编辑分组</el-button>

              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pageQuery.current"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="pageQuery.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="编辑分组"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="20%">
      <el-form :model="addTeamForm">

        <el-form-item label="选择分组"
                      :label-width="formLabelWidth">
          <el-select @change="setUserType"
                     v-model="addTeamForm.typeStr"
                     placeholder="请选择分类">
            <el-option v-for="item in userTypeList"
                       :key="item.key"
                       :label="item.value"
                       :value="item"></el-option>

          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="updateUserType">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增用户"
               :visible.sync="userFormVisible"
               :close-on-click-modal="false"
               width="20%">
      <el-form :model="userForm">
        <el-form-item label="用户名："
                      prop="username"
                      :label-width="formLabelWidth">
          <el-input v-model="userForm.username"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码："
                      prop="password"
                      :label-width="formLabelWidth">
          <el-input v-model="userForm.password"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="jira账号："
                      prop="jira_name"
                      :label-width="formLabelWidth">
          <el-input v-model="userForm.jira_name"
                    autocomplete="off"></el-input>

        </el-form-item>
        <el-form-item label="jira口令："
                      prop="api_token"
                      :label-width="formLabelWidth">
          <el-input v-model="userForm.api_token"
                    autocomplete="off"></el-input>

        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeUserVisible">取 消</el-button>
        <el-button type="primary"
                   @click="addUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {

  created () {
    this.getPageList()
    this.getUserType()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
  },
  data () {
    return {
      userForm: {
        username: '',
        password: '',
        jira_name: '',
        api_token: '',

      },
      userFormVisible: false,
      userTypeList: [],
      formLabelWidth: '120px',
      dialogFormVisible: false,
      addTeamForm: {
        id: '',
        username: '', // 人员名称
        type: '', // 分组key
        typeStr: '' // 分组value
      },
      pageList: [],
      total: 0,
      updateUserUse: {
        id: '',
        username: '',
        isUse: ''
      },

      pageQuery: {
        current: 1,
        size: 10,
        username: '',
        nickname: ''
      },
      env: [],
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称，
      parentName: ''
    }
  },
  methods: {
    async getPageList () {
      const { data: res } = await this.$http.post('py/server/user/getPageList', this.pageQuery)

      if (res.success) {
        this.pageList = res.data.records

        this.total = res.data.total
      }
    },

    async isUse (row, isUse) {
      this.updateUserUse.id = row.id
      this.updateUserUse.username = row.username
      this.updateUserUse.isUse = isUse
      const { data: res } = await this.$http.post('py/server/user/isUser', this.updateUserUse)
      if (res.success) {
        this.$message.success('请求成功')
        this.getPageList()
      }
    },
    async getLDAPUser () {
      const { data: res } = await this.$http.get('api/server/user/getLDAPUser')
      if (res.success) {
        this.$message.success('同步成功')
        this.getPageList()
      }
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage
      this.getPageList()
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent
      this.getPageList()
    },
    // 获取用户分组
    async getUserType () {
      const { data: res } = await this.$http.get('py/server/user/getUserType', '')

      this.userTypeList = res.data
    },
    async updateUserType () {
      const { data: res } = await this.$http.post('py/server/user/updateUserType', this.addTeamForm)

      if (!res.success) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.addTeamForm = {}
      this.dialogFormVisible = false
      this.getPageList()
    },
    openDialogVisible (row) {
      this.dialogFormVisible = true
      this.addTeamForm.username = row.username
      this.addTeamForm.id = row.id
    },
    closeDialogVisible () {
      this.addTeamForm = {}
      this.dialogFormVisible = false
    },
    setUserType (e) {
      this.addTeamForm.type = e.key
      this.addTeamForm.typeStr = e.value
    },
    async addUser () {
      const { data: res } = await this.$http.post('py/server/user/addUser', this.userForm)
      if (res.success) {
        this.$message.success(res.msg)
        this.closeUserVisible()
        this.getPageList()
      } else {
        this.$message.error(res.msg)

      }

    },
    openUserVisible () {
      this.userFormVisible = true
    },
    closeUserVisible () {
      this.userFormVisible = false
      this.userForm = {
        username: '',
        password: '',
        jira_name: '',
        api_token: ''

      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
