import jsrsasign from 'jsrsasign';

let rsa_public_key = "b3764d7f25497d340ae81953af7a898e1c1ea320489b17868906c537b33b6446f24ce902227db22540bfd80da256b4fc53b385ea7a4233a4e1e02d0be19aac8fac1e23830de13968bb089f767533716c03032172f8e32e2f105e22ff12ecc0f0ffae68b70f70fa6994dafd56e011178b339791b762413a29d1960b4fbe133c50bc8c3bf11bd985309440d1b9e3770dcc291f91e5388fe95dee06b2de9ca0ad35c0b89ba9e2ac282276bb09f477f3d0ffc5384f245b900b4d17eb3d16129d0666f02668459ea987531ab8b00c184ffd198d74114ccf34691ba63152812c3f4bbd415fef1770f9b4a4a178ae89eed96fd99c73ff750356a5905ae268a8cdff0657";
let rsa_public_exponent = "10001";

// rsa加密
// 不可加密数字，否则解密为空
function rsa(text) {
    let pub = jsrsasign.KEYUTIL.getKey({ n: rsa_public_key, e: rsa_public_exponent })
    let enc = jsrsasign.KJUR.crypto.Cipher.encrypt(text, pub)
    return `rsa.${enc}`
}


function md5(str){
    let crypto;
    if (typeof window!== 'undefined') {
        // 浏览器环境，引入crypto-js
        const CryptoJS = require('crypto-js');
        crypto = CryptoJS;
    } else {
        // Node.js环境，引入原生crypto
        crypto = require(crypto);
    }


    if (typeof window === 'undefined') {
        // Node.js环境下的MD5加密方式，这里只是示例，实际可能需根据需求调整
        const hash = crypto.createHash('md5');
        hash.update(str);
        const md5Hash = hash.digest('hex');
        return md5Hash
    } else {
        // 浏览器环境下用crypto-js进行MD5加密
        const md5Hash = crypto.MD5(str).toString();
        return md5Hash
    }
}

export { md5, rsa };

