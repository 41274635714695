<template>
  <el-form :ref="myConfig.ref"
           :model="myFormData"
           :size="myConfig.size"
           class="text-left"
           :rules="formRules"
           :label-width="myConfig.labelWidth"
           :validate-on-rule-change="false"
           :inline="myConfig.inlineFlag">
    <template v-for="(item,$index) in itemList">
      <el-form-item :class="item.class"
                    :style="{width:item.width}"
                    :label="item.label"
                    :prop="item.prop"
                    :key="$index"
                    v-if="(typeof(item.show)=='function'?item.show():(item.show!=undefined?item.show:true))">
        <!--表单label-->
        <template v-if="item.slot&&typeof(item.labelFun) == 'function'"
                  slot='label'>
          <span v-html='item.labelFun()'></span>
        </template>
        <!--表单前缀-->
        <template v-if="item.slot&&(item.slotType=='prepend'||(typeof(item.slotType)=='object'&&item.slotType.indexOf('prepend')!=-1))">
          <slot :name="(item.slotName?item.slotName:item.prop)+'-prepend'"
                :data="item" />
        </template>
        <!-- 输入框 -->
        <el-input v-if="item.type==='input'"
                  v-model="myFormData[item.prop]"
                  suffix-icon='xxxx'
                  :disabled="(typeof(item.disabled)=='function'?item.disabled():item.disabled)"
                  :placeholder="item.placeholder?item.placeholder:''"
                  @change="item.change && item.change(item.prop)"></el-input>
        <!-- 数字输入框 -->
        <el-input v-if="item.type==='number'"
                  v-model="myFormData[item.prop]"
                  type='number'
                  :disabled="item.disabled"
                  :placeholder="item.placeholder?item.placeholder:''"
                  @change="item.change && item.change(item.prop)"></el-input>
        <!-- 密码框 -->
        <el-input v-if="item.type==='password'"
                  type='password'
                  show-password
                  v-model="myFormData[item.prop]"
                  :disabled="item.disabled"
                  :placeholder="item.placeholder?item.placeholder:''"></el-input>
        <!-- 文本域 -->
        <el-input v-if="item.type==='textarea'"
                  type="textarea"
                  :disabled="item.disabled"
                  v-model="myFormData[item.prop]"
                  :placeholder="item.placeholder?item.placeholder:''"
                  @change="item.change && item.change(item.prop)"></el-input>
        <!-- 下拉框 -->
        <template>
          <el-select v-if="item.type==='select'"
                     :filterable="item.filterable?item.filterable:false"
                     :multiple="item.multiple"
                     :reserve-keyword="item.reserveKeyword"
                     v-model="myFormData[item.prop]"
                     :disabled="(typeof(item.disabled)=='function'?item.disabled():item.disabled)"
                     :class="item.class"
                     @change="item.change && item.change(item.prop)">
            <el-option v-for="(op, index) in item.options"
                       :label="item.optionLabel?op[item.optionLabel]:(op.label?op.label:op)"
                       :value="item.optionValue?op[item.optionValue]:(op.value?op.value:op)"
                       :key="index"></el-option>
          </el-select>
        </template>

        <!-- 单选 -->
        <el-radio-group v-if="item.type==='radio'"
                        v-model="myFormData[item.prop]"
                        :disabled="item.disabled"
                        @change="item.change && item.change(item.prop)">
          <el-radio v-for="(ra,index) in item.radios"
                    :label="ra.value?ra.value:ra"
                    :key="index">{{ra.label?ra.label:ra}}</el-radio>
        </el-radio-group>
        <!-- 复选框-->
        <template v-if="item.type=='checkbox'&&item.isSingle">
          <el-checkbox v-model="myFormData[item.prop]"
                       :disabled="item.disabled"
                       @change="item.change && item.change(item.prop)">
            <template v-if="item.slot">
              <slot :name="item.prop"
                    :data="item" />
            </template>
            <template v-else>{{item.checkboxLabel?item.checkboxLabel:item.label}}</template>
          </el-checkbox>
        </template>
        <!-- 复选框组-->
        <el-checkbox-group @change="item.change && item.change(item.prop)"
                           v-if="item.type==='checkbox'&&(!item.isSingle)"
                           v-model="myFormData[item.prop]"
                           :disabled="item.disabled">
          <el-checkbox v-for="(ch,index) in item.checkboxs"
                       :label="item.checkboxLabel?ch[item.checkboxLabel]:ch.value?ch.value:ch"
                       :key="index">{{item.checkboxLabel?ch[item.checkboxLabel]:ch.label?ch.label:ch}}</el-checkbox>
        </el-checkbox-group>
        <!-- 日期 -->
        <el-date-picker v-if="item.type==='date'"
                        v-model="myFormData[item.prop]"
                        :disabled="item.disabled"></el-date-picker>
        <!-- 时间 -->
        <el-time-picker v-if="item.type==='time'"
                        v-model="myFormData[item.prop]"
                        :disabled="item.disabled"></el-time-picker>
        <!-- 日期时间 -->
        <el-date-picker v-if="item.type==='dateTime'"
                        type="datetime"
                        v-model="myFormData[item.prop]"
                        :disabled="item.disable"></el-date-picker>
        <!-- 日期范围 -->
        <el-date-picker v-if="item.type==='daterange'"
                        type="daterange"
                        v-model="myFormData[item.prop]"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled="item.disabled"
                        :value-format="item.valueFormat"
                        @change="item.change && item.change(item.prop)"></el-date-picker>
        <!-- 开关 -->
        <el-switch v-if="item.type==='switch'"
                   v-model="myFormData[item.prop]"
                   :disabled="item.disabled"></el-switch>
        <!-- slot-->
        <template v-if="item.type==='slot'">
          <!-- <slot :name="item.prop" :data="item" /> -->
          <slot :name="item.slotName?item.slotName:item.prop"
                :data="item" />
        </template>
        <!-- 按钮 -->
        <span v-if="item.type==='button'">
          <template v-for="btn in item.btnList">
            <el-button v-if="(typeof(btn.show)=='function'?btn.show():(btn.show!=undefined?btn.show:true))"
                       :key="btn.label"
                       :disabled="typeof(btn.disabled)=='function'?btn.disabled() : btn.disabled"
                       :type="btn.type || type"
                       :size="btn.size || size "
                       :icon="btn.icon"
                       plain
                       @click="btn.handle(item)">{{btn.label}}</el-button>
          </template>
        </span>
        <!--form表单 append-->
        <template v-if="item.slot&&(item.slotType=='append'||(typeof(item.slotType)=='object'&&item.slotType.indexOf('append')!=-1))">
          <slot :name="(item.slotName?item.slotName:item.prop)+'-append'"
                :data="item" />
        </template>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
export default {
  name: 'SearchComponent',
  props: {
    config: {
      type: Object,
      default: () => ({ labelWidth: '100px', ref: 'formRef', inlineFlag: true, size: 'mini' })
    },
    itemList: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => { }
    },
    formRules: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      myFormData: this.formData,
      myConfig: Object.assign({ labelWidth: '100px', ref: 'formRef', inlineFlag: true, size: 'mini' }, this.config),
      confirmLoading: false
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style>
.el-form-item__label {
  font-weight: bold;
  font-size: 12px;
}
.el-form-item--mini.el-form-item {
  margin-bottom: 10px;
}
.el-checkbox-button__inner,
.el-radio {
  font-weight: normal;
}
.self-icon-upload {
  font-size: 26px;
  color: #1890ff;
}
.self-position {
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 200px;
}
</style>
