<!--需求详情页面-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin-bottom: 10px;">
      <div style="position: relative;">
        <el-page-header @back="goBack"
                        content="需求详情页"
                        style="margin-bottom: 7px;">
        </el-page-header>
        <div style="position: absolute; right: 10%;top: 12%;">
          <el-button type="primary"
                     size="mini"
                     v-show="cr_button_type"
                     @click="codeReviewVisible=true">发起CR</el-button>
          <el-button type="primary"
                     v-show="tc_button_type"
                     @click="openTipsVisible('tc_button')"
                     size="mini">发起提测</el-button>
          <el-button type="primary"
                     size="mini"
                     v-show="show_button_type"
                     @click="caseReviewVisible=true">showcase</el-button>
          <el-button type="primary"
                     v-show="start_button_type"
                     @click="openTipsVisible('start_button')"
                     size="mini">开始测试</el-button>
          <el-button type="primary"
                     v-show="finish_button_type"
                     @click="openTipsVisible('finish_button')"
                     size="mini">测试完成</el-button>
          <el-button type="primary"
                     v-show="online_button_type"
                     @click="openTipsVisible('online_button')"
                     size="mini">上线</el-button>
        </div>
      </div>

    </el-card>
    <el-card style="position: relative;">

      <div style="position: absolute;left: 400px;">
        <el-divider direction="vertical"></el-divider>

        <el-tag>{{ this.demandInfo.demandStatusStr }}</el-tag>
        .
      </div>

      <div>
        <el-tabs v-model="activeTabName"
                 @tab-click="setActiveTabName">
          <el-tab-pane label="基本信息"
                       name="first">

            <el-card>
              <el-descriptions :column="1"
                               style="font-size: 16px;font-weight: bold">
                <el-descriptions-item label="需求标题">
                  {{ this.demandInfo.demandName }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="5"
                               style="font-size: 16px;">
                <!-- <el-descriptions-item label="所属系统">{{ this.demandInfo.systemName }}</el-descriptions-item> -->
                <el-descriptions-item label="需求类型">{{ this.demandInfo.demandTypeStr }}</el-descriptions-item>
                <el-descriptions-item label="产品负责人">{{ this.demandInfo.creatorName }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="1"
                               style="font-size: 16px;">
                <el-descriptions-item label="需求描述">
                  <el-input type="textarea"
                            disabled
                            maxlength="300"
                            autosize
                            :value=this.demandInfo.demandDescription
                            style="width: 500px;"></el-input></el-descriptions-item>
                <el-descriptions-item label="原型地址">
                  <a :href=this.demandInfo.demandUrl
                     target="_blank">
                    <el-tag>{{this.demandInfo.demandUrl}}</el-tag>
                  </a>
                </el-descriptions-item>
                <el-descriptions-item label="用例地址">
                  <div v-if="this.demandInfo.exampleUrl != null">
                    <a :href=this.demandInfo.exampleUrl
                       target="_blank">
                      <el-tag>{{this.demandInfo.exampleUrl}}</el-tag>
                    </a>
                  </div>
                  <div v-else>
                    <el-button type="primary"
                               size="mini"
                               @click="addUrlVisible = true">添加用例地址</el-button>
                  </div>

                </el-descriptions-item>
              </el-descriptions>

            </el-card>

          </el-tab-pane>
          <el-tab-pane label="排期"
                       name="second">
            <EidtSchedule :activename="activeTabName"></EidtSchedule>
          </el-tab-pane>
          <el-tab-pane label="研发信息"
                       name="third">
            <el-card>
              <el-descriptions :column="1"
                               style="font-size: 16px;">
                <el-descriptions-item label="产品经理">{{ this.userInfo.prod }}</el-descriptions-item>
                <el-descriptions-item label="后端开发">{{ this.userInfo.java }}</el-descriptions-item>
                <el-descriptions-item label="前端开发">{{ this.userInfo.fe }}</el-descriptions-item>

                <el-descriptions-item label="测试">{{ this.userInfo.test }}</el-descriptions-item>
              </el-descriptions>
            </el-card>
          </el-tab-pane>
          <el-tab-pane :label="labelStr"
                       name="fourth">
            <el-card style="height: 650px;">

              <search-component :key="timer"
                                :itemList="searchList"
                                :formData="searchData">
              </search-component>

              <div>
                <el-table :data="bugPageList.records"
                          stripe
                          height="550"
                          border
                          style="width: 96%;"
                          align="center">

                  <el-table-column label="bug编号"
                                   width="80px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.row.id }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="bug标题"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <el-button type="text"
                                   @click="getBugDetail(scope.row)">{{ scope.row.title }}</el-button>

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="bug状态"
                                   width="90px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper"
                           style="line-height: 20px;">
                        <el-tag size="medium"
                                v-if="scope.row.statusStr === '已解决'"
                                type="success"
                                effect="dark">{{scope.row.statusStr }}</el-tag>
                        <el-tag size="medium"
                                v-if="scope.row.statusStr === '已关闭'"
                                type="info"
                                effect="dark">{{scope.row.statusStr }}</el-tag>
                        <el-tag size="medium"
                                v-if="scope.row.statusStr === '待解决' || scope.row.statusStr === '重新打开'"
                                type="warning"
                                effect="dark">{{scope.row.statusStr }}</el-tag>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="bug等级"
                                   width="80px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">

                        <span size="medium">{{ scope.row.levelStr }}</span>

                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="bug类型"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.typeStr }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="创建人"
                                   width="90px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.creatorName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="处理人"
                                   width="90px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.handleName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="发起时间"
                                   width="160px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.createTime }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="更新时间"
                                   width="160px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.updateTime }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="所用时长"
                                   width="120px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.expend }}</span>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
              <el-pagination @size-change="handleSizeChange"
                             @current-change="handleCurrentChange"
                             :current-page="bugPageReqData.current"
                             :page-sizes="[10, 20, 30, 40]"
                             :page-size="bugPageReqData.size"
                             layout="total, sizes, prev, pager, next, jumper"
                             :total="total">
              </el-pagination>
            </el-card></el-tab-pane>

        </el-tabs>

      </div>
    </el-card>
    <el-dialog title="发起CR"
               :visible.sync="codeReviewVisible"
               width="30%"
               :close-on-click-modal="false">
      <el-form :model="codeReviewForm">
        <el-form-item label="预期提测时间："
                      :label-width="formLabelWidth">

          <el-date-picker v-model="codeReviewForm.anticipatedTestDate"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期">
          </el-date-picker>

        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="codeReviewVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="codeReview">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="showcase"
               :visible.sync="caseReviewVisible"
               width="30%"
               :close-on-click-modal="false">
      <el-form :model="caseReviewForm">
        <el-form-item label="预期上线时间："
                      :label-width="formLabelWidth">

          <el-date-picker v-model="caseReviewForm.anticipatedOnlineDate"
                          type="date"
                          placeholder="选择日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeCaseReviewVisible">取 消</el-button>
        <el-button type="danger"
                   @click="caseReview('stop')">拒 绝</el-button>
        <el-button type="success"
                   @click="caseReview('pass')">通 过</el-button>

      </div>
    </el-dialog>
    <el-dialog title="提示"
               :visible.sync="tipsVisible"
               width="30%">
      <span>{{tipsText}}</span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="tipsVisible = false">取 消</el-button>
        <el-button type="primary"
                   v-if="button_type === 'tc_button'"
                   @click="begintest">确 定</el-button>
        <el-button type="primary"
                   v-if="button_type === 'start_button'"
                   @click="testing">确 定</el-button>
        <el-button type="primary"
                   v-if="button_type === 'finish_button'"
                   @click="waitOnline">确 定</el-button>
        <el-button type="primary"
                   v-if="button_type === 'online_button'"
                   @click="online">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加用例地址"
               :visible.sync="addUrlVisible"
               width="30%"
               :close-on-click-modal="false">
      <el-form :model="addExampleUrlForm"
               :rules="addExampleUrlFormRules"
               ref="addExampleUrlFormRef">

        <el-form-item prop="exampleUrl"
                      label="用例地址："
                      :label-width="formLabelWidth">
          <el-input v-model="addExampleUrlForm.exampleUrl"
                    autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeAddUrlVisible">取 消</el-button>

        <el-button type="primary"
                   @click="addExampleUrl">确 定</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import EidtSchedule from '../schedule/EidtSchedule.vue'
import SearchComponent from '../../common/Serach.vue'
export default {
  components: {
    SearchComponent,
    EidtSchedule

  },
  data () {
    return {
      labelStr: '缺陷',
      searchList: [
        { label: 'bug编号：', prop: 'id', type: 'input', change: this.getEvent, size: 'mini' },
        { label: 'bug标题：', prop: 'title', type: 'input', change: this.getEvent },
        {
          label: 'bug状态：',
          prop: 'status',
          type: 'select',
          multiple: true,
          options: [ // bug类型
            {
              label: '待解决',
              value: '00'
            },
            {
              label: '已解决',
              value: '01'
            },
            {
              label: '重新打开',
              value: '02'
            },
            {
              label: '已关闭',
              value: '03'
            },
            {
              label: '已驳回',
              value: '04'
            }
          ]
        },
        {
          label: 'bug等级：',
          prop: 'level',
          type: 'select',
          multiple: true,
          options: [ // bug等级
            {
              label: '紧急',
              value: '00'
            },
            {
              label: '严重',
              value: '01'
            },
            {
              label: '一般',
              value: '02'
            }
          ]
        },
        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() },
            { type: 'primary', label: '新增bug', size: 'mini', icon: 'el-icon-plus', handle: row => this.addBug() }
          ]
        }
      ],
      timer: '',
      searchData: {
        id: '', // 可以赋予初始值
        title: '',
        status: [],
        level: [],
        handleName: '',
        demandId: '',
        current: 1,
        size: 10,
        pages: 1
      },
      tipsVisible: false, // 二次确认提示框
      tipsText: '', // 提示语
      button_type: '', // 按钮类型
      bugStatusList: [
        { id: '001', name: '待解决' },
        { id: '002', name: '已解决' },
        { id: '003', name: '重新打开' },
        { id: '004', name: '已关闭' },
        { id: '005', name: '被驳回' }

      ],
      formInline: {
        bugNo: '', // bug编号
        handers: [], // bug负责人
        level: [], // bug严重等级
        bugType: [], // bug类型
        bugStatus: []// bug状态
      },

      bugPageList: '', // bug列表页
      bugPageReqData: {
        id: '', // bug 编号
        status: [], // bug状态
        createCode: [], // 创建人
        handleCode: [], // 负责人
        type: [], // bug类型
        level: [], // bug级别
        demandId: [], // 需求id， 必填
        current: 1,
        size: 10,
        pages: 1

      }, // 请求bug列表页的参数

      codeReviewVisible: false,
      caseReviewVisible: false,
      formLabelWidth: '120px',
      activeTabName: 'first',
      items: [

        { type: 'success', label: '已解决' },
        { type: 'info', label: '已关闭' },
        { type: 'warning', label: '待解决' }
      ],

      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      demandInfo: '', // 需求信息
      demandId: '', // 需求id,
      userInfo: '', // 研发信息,
      codeReviewForm: {
        // 发起CR表单数据
        anticipatedTestDate: '', // 预计提测时间
        id: ''
      },
      caseReviewForm: {

        id: '',
        anticipatedOnlineDate: ''

      },
      addExampleUrlFormRules: {
        exampleUrl: [
          { required: true, message: '用例地址不能为空', trigger: 'blur' }
        ]
      },

      roleType: '', // 当前登录人的角色类型
      total: 0,
      bugLevel: [], // bug等级列表
      userList: [], // bug所有负责人
      bugType: [], // bug类型
      bugStatus: [], /// bug状态.
      online_button_type: false, // 上线按钮状态
      finish_button_type: false, // 完成测试按钮状态
      start_button_type: false, // 开始测试按钮状态
      show_button_type: false, // showcase按钮状态
      tc_button_type: false, // 提测按钮状态
      cr_button_type: false, // 发起cr 按钮状态
      addExampleUrlForm: {
        id: '',
        exampleUrl: ''
      },
      addUrlVisible: false

    }
  },

  created () {
    this.demandId = window.sessionStorage.getItem('demandId')
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.activeTabName = window.sessionStorage.getItem('tabName')
    this.roleType = window.sessionStorage.getItem('roleType')
    this.getDemandInfoBaseVO()


  },

  methods: {
    closeCaseReviewVisible () {
      this.caseReviewVisible = false
      this.caseReviewForm = {
        id: '',
        anticipatedOnlineDate: ''
      }
    },
    closeAddUrlVisible () {
      this.addUrlVisible = false
      this.addExampleUrlForm = {}
    },
    async getDemandInfoUserVO () {
      // 查询该需求的 研发人员信息
      const { data: res } = await this.$http.post('/py/server/project/getDemandInfoUserVO?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.userInfo = res.data
    },
    button_is_show () {
      if (this.demandInfo.demandStatus === '02' && this.roleType === '后端开发') {
        this.cr_button_type = true
      }
      if (this.demandInfo.demandStatus === '03' && this.roleType === '后端开发') {
        this.tc_button_type = true
      }
      if (this.demandInfo.demandStatus === '04' && this.roleType === '测试') {
        this.show_button_type = true
      }
      if (this.demandInfo.demandStatus === '05' && this.roleType === '测试') {
        this.start_button_type = true
      }
      if (this.demandInfo.demandStatus === '06' && this.roleType === '测试') {
        this.finish_button_type = true
      }
      if (this.demandInfo.demandStatus === '07' && this.roleType === '测试') {
        this.online_button_type = true
      }
    },
    async getDemandInfoBaseVO () {
      // 查询需求的基本信息
      const { data: res } = await this.$http.post('/py/server/project/getDemandInfoBaseVO?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.demandInfo = res.data
      this.button_is_show()
    },
    addBug () {
      this.$router.push('/addBug')
    },

    getBugDetail (row) {
      this.$router.push({
        path: '/bugDetail',
        query: { id: row.id, demandId: row.demandId, handleCode: row.handleCode }
      })
    },
    async codeReview () {
      this.codeReviewForm.id = this.demandId
      const { data: res } = await this.$http.post('/py/server/project/review', this.codeReviewForm)
      if (!res.success) return this.$message.error(res.msg)
      this.$message.success('成功发起CR')
      this.cr_button_type = false
      this.codeReviewVisible = false
      this.getDemandInfoBaseVO()
    },
    goBack () {
      this.$router.back(0)
    },
    setActiveTabName (data) {

      this.activeTabName = data.$options.propsData.name

      if (this.activeTabName === 'third') {
        window.sessionStorage.setItem('tabName', this.activeTabName)
        this.getDemandInfoUserVO()
      }
      if (this.activeTabName === 'first') {
        window.sessionStorage.setItem('tabName', this.activeTabName)
        this.getDemandInfoBaseVO()
      }
      if (this.activeTabName === 'fourth') {
        window.sessionStorage.setItem('tabName', this.activeTabName)
        this.getBugPageList(this.bugPageReqData)
      } if (this.activeTabName === 'second') {
        window.sessionStorage.setItem('tabName', this.activeTabName)
      }
    },

    async caseReview (caseType) {
      /**
       * @description：用于showcase，通过代表提测成功，失败代表提测拒绝
       */
      this.caseReviewForm.id = this.demandId
      console.log(this.caseReviewForm)
      if (caseType === 'pass') {
        const { data: res } = await this.$http.post('py/server/project/ShowCase', this.caseReviewForm)
        if (!res.success) return this.$message.error(res.msg)
        this.caseReviewVisible = false
        this.caseReviewForm = {
          id: '',
          anticipatedOnlineDate: ''
        }
        this.show_button_type = false
        this.$message.success('提测通过')
        this.getDemandInfoBaseVO()
      }
      if (caseType === 'stop') {
        const { data: res } = await this.$http.post('py/server/project/proposeRefuse?id=' + this.demandId)
        if (!res.success) return this.$message.error(res.msg)
        this.caseReviewVisible = false
        this.caseReviewForm = {
          id: '',
          anticipatedOnlineDate: ''
        }
        this.show_button_type = false
        this.$message.success('提测拒绝')
        this.getDemandInfoBaseVO()
      }
    },
    async getBugPageList (data) {
      /**
       * @description：获取bug列表页
       */
      data.demandId = this.demandId
      // console.log(data.level.length)
      // if (data.level.length == 0)  {
      //   data.level = ['00', '01', '02']
      // }
      // if (data.status.length == 0) {
      //   data.status = ['00', '01', '02', '03', "04"]
      // }
      const { data: res } = await this.$http.post('py/server/project/getBugPageList', data)

      if (!res.success) return this.$message.error(res.msg)
      this.bugPageList = res.data
      this.labelStr = '缺陷（' + res.data.total + '）'
      this.total = res.data.total
      this.bugPageReqData = {
        id: '', // bug 编号
        status: [], // bug状态
        createCode: [], // 创建人
        handleCode: [], // 负责人
        type: [], // bug类型
        level: [], // bug级别
        demandId: [], // 需求id， 必填
        current: 1,
        size: 10,
        pages: 1

      }
      this.formInline = {}
    },
    handleSizeChange (newPage) {
      /**
       * @description：用于分页
       */
      this.bugPageReqData.size = newPage
      this.getBugPageList(this.searchData)
    },
    handleCurrentChange (newCurrent) {
      /**
       * @description：用于分页
       */
      this.bugPageReqData.current = newCurrent
      this.getBugPageList(this.searchData)
    },
    async getUserListByDemand () {
      /**
       * @description：获取该需求中，关联的相关人员
       */
      const { data: res } = await this.$http.get('py/server/project/getUserListByDemand?id=' + this.demandId)

      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    async getBugLevel () {
      /**
       * @description：获取bug严重级别枚举
       */
      const { data: res } = await this.$http.get('py/server/project/getBugLevel')
      if (!res.success) return this.$message.error(res.msg)
      this.bugLevel = res.data
    },
    async getBugType () {
      /**
       * @description：获取bug类型枚举
       */
      const { data: res } = await this.$http.get('py/server/project/getBugType')
      if (!res.success) return this.$message.error(res.msg)
      this.bugType = res.data
    },
    async getBugStatus () {
      /**
       * @description：获取bug状态枚举
       */
      const { data: res } = await this.$http.get('py/server/project/getBugStatus')
      if (!res.success) return this.$message.error(res.msg)
      this.bugStatus = res.data
    },
    async begintest () { // 提测接口
      /**
       * @description: 开发提测接口，点击后，选择提测
       */
      const { data: res } = await this.$http.post('py/server/project/propose?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.tc_button_type = false
      this.tipsVisible = false
      this.$message.success('提测成功')
      this.getDemandInfoBaseVO()
    },
    async testing () {
      /**
       * @description：开始测试接口，点击后需求变为测试中
       */
      const { data: res } = await this.$http.post('py/server/project/testing?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.start_button_type = false
      this.tipsVisible = false
      this.$message.success('提交成功')
      this.getDemandInfoBaseVO()
    },
    async waitOnline () {
      /**
       * @description：测试完成接口，点击后，需求状态变为测试完成
       */
      const { data: res } = await this.$http.post('py/server/project/waitOnline?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.finish_button_type = false
      this.tipsVisible = false
      this.$message.success('测试完成')
      this.getDemandInfoBaseVO()
    },
    async online () {
      /**
       * @description：需求上线接口
       */
      const { data: res } = await this.$http.post('py/server/project/online?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)
      this.online_button_type = false
      this.tipsVisible = false
      this.$message.success('上线完成')
      this.getDemandInfoBaseVO()
    },
    openTipsVisible (button) {
      if (button === 'tc_button') {
        this.tipsVisible = true // 二次确认提示框
        this.button_type = button
        this.tipsText = '是否发起提测？' // 提示语
      }
      if (button === 'start_button') {
        this.tipsVisible = true // 二次确认提示框
        this.button_type = button
        this.tipsText = '是否开始测试？' // 提示语
      }
      if (button === 'finish_button') {
        this.tipsVisible = true // 二次确认提示框
        this.button_type = button
        this.tipsText = '是否测试完成？' // 提示语
      }
      if (button === 'online_button') {
        this.tipsVisible = true // 二次确认提示框
        this.button_type = button
        this.tipsText = '是否上线？' // 提示语
      }
    },
    getEvent (res) {
      console.log(this.searchData)
    },
    search () {
      this.getBugPageList(this.searchData)
    },
    reset () {
      this.searchData = {
        id: '', // 可以赋予初始值
        title: '',
        status: [],
        level: [],
        handleName: '',
        demandId: '',
        current: 1,
        size: 10,
        pages: 1
      }
      this.timer = new Date().getTime()

      this.getBugPageList(this.searchData)
    },

    async addExampleUrl () {
      this.$refs.addExampleUrlFormRef.validate(async (valid) => {
        if (!valid) return
        this.addExampleUrlForm.id = this.demandId
        const { data: res } = await this.$http.post('py/server/project/addExampleUrl', this.addExampleUrlForm)
        if (res.success) {
          this.addUrlVisible = false
          this.$message.success('添加成功')
          this.getDemandInfoBaseVO()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  mounted () {
    this.getBugPageList(this.searchData)
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-table .el-table__cell {
  padding: 5px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.select {
  position: absolute;
  right: 5%;
  z-index: 50;
  top: 4%;
}
</style>
