import Vue from 'vue'
import App from './App.vue'
import router from '.'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'default-passive-events'
import { ElementTiptapPlugin } from 'element-tiptap'
import { Loading } from 'element-ui';
import moment from 'moment'
import store from './store'; // 引入 store 文件

// import element-tiptap 样式
import 'element-tiptap/lib/index.css'
// 引入自定义样式
import './assets/css/custom.css'
// 安装 element-tiptap 插件
import $ from 'jquery'

window.jQuery = $
window.$ = $

Vue.directive('highlight',{
	bind(el,binding,vnode){
		const {value} = binding;
		if(value && typeof value==='object'){
			const {hWord,word} = value;
			el.innerHTML = word.replace(new RegExp(hWord,'ig'),(a)=>{
				return `<span style="color:red">${a}</span>`
			})
		}
	},
	update(el,binding,vnode){
		const {value} = binding;
		if(value&&typeof value==='object'){
			const {hWord,word} = value;
			el.innerHTML = word.replace(new RegExp(hWord,'ig'),(a)=>{
				return `<span style="color:red;font-weight:bold">${a}</span>`
			})
		}
	}
})

Vue.use(ElementTiptapPlugin, {
  lang: 'zh' // 设置语言为中文
})

Vue.use(VueQuillEditor);
Vue.use(Loading.directive);

Vue.prototype.$http = axios

Vue.prototype.$moment = moment

axios.defaults.baseURL = '/'
Vue.config.productionTip = false
axios.interceptors.request.use((config) => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})


// 设置HTTP请求拦截器
axios.interceptors.response.use(null, (error) => {
  // 如果状态码是401，表示token失效
  if (error.response && error.response.status === 401) {
    // 清除本地存储的token
    localStorage.removeItem('token');
    // 跳转到登录页面，这里的'/login'是登录页面的路由路径
    router.push('/login');
  }
  // 对于其他错误，保持原样抛出
  return Promise.reject(error);
});

new Vue({
  router,
	store,//注册store
  render: (h) => h(App)
}).$mount('#app')
