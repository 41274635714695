<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>

      <div>
        <el-button type="primary"
                   @click="
                     dialogFormVisible=true">新增日程</el-button>

      </div>

      <div>
        <div class="parent_box">
          <div class="table_box">
            <el-checkbox-group v-model="checkList"
                               @change="selectWorking">
              <el-checkbox label="需求"></el-checkbox>
              <el-checkbox label="会议"></el-checkbox>
              <el-checkbox label="缺陷"></el-checkbox>
              <el-checkbox label="本地需求"></el-checkbox>

            </el-checkbox-group>

          </div>
          <div class="select">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                关注人：{{ lookName }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="myAccountCode"
                                  :value="myAccountName">我</el-dropdown-item>
                <div v-for="user in userList"
                     :key="user.code">

                  <el-dropdown-item v-if="user.key != myAccountCode"
                                    :command="user.code"
                                    :value="user.value">{{ user.value }}</el-dropdown-item>
                </div>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

      </div>

      <full-calendar style="height:630px"
                     ref="calendar"
                     class="calendar"
                     id="calendar"
                     :config="config"
                     :events="events" />
    </el-card>

    <el-dialog title="
                     新增日程"
               :visible.sync="dialogFormVisible"
               width="30%"
               :close-on-click-modal="false"
               modal>
      <el-form :model="planForm">

        <el-form-item label="日程描述："
                      :label-width="formLabelWidth">
          <el-input autocomplete="off"
                    v-model="planForm.title"
                    size="small"
                    placeholder="日程描述"></el-input>

        </el-form-item>

        <el-form-item label="工时："
                      :label-width="formLabelWidth">
          <el-input autocomplete="off"
                    v-model="planForm.hours"
                    size="small"
                    placeholder="工时(时/天/人)"
                    style="width:217px"></el-input>

        </el-form-item>
        <el-form-item label="周期："
                      :label-width="formLabelWidth">
          <el-date-picker v-model="planForm.cycleTime"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary"
                   @click="addPlan">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script >

import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import tippy from 'tippy.js' // 引入 tippy.js
import 'tippy.js/dist/tippy.css'// 引入 tippy.js
import 'tippy.js/themes/light.css' // 引入主题
import 'tippy.js/animations/scale.css'



export default {
  components: {
    FullCalendar

  },

  data () {
    return {
      checkList: ['需求', '会议', '缺陷', '本地需求'],
      activeTabName: 'first',
      demand_type: true,
      work_type: true,
      metting_type: true,
      weekFirst: '',
      weekEnd: '',
      WorkDateForm: {
        flag: null, // true 查上一周， false 查下一周
        num: 7,
        workDate: ''
      },
      events: [

      ],

      config: {
        // 在日历顶部定义按钮和标题
        // title 、prev、next、prevYear、nextYear、today、month、basicWeek、basicDay
        header: {
          left: '',
          center: 'title',
          right: 'today prev,next basicDay basicWeek month'
        },

        // 自定义按钮文字
        buttonText: {
          basicWeek: '周',
          today: '今天',
          basicDay: '天',
          month: '月'
        },
        defaultDate: this.weekFirst, // 默认时间
        // 日历切换时间范围
        validRange: {
          start: '2023-05-01',
          end: '2030-05-01'
        },
        locale: 'zh-cn', // 中文
        allDaySlot: false, // 是否显示allDay
        defaultView: 'basicWeek', // 显示默认视图
        showNonCurrentDates: false, // 是否在本月中显示其他月

        // 事件
        eventMouseover: this.eventMouseover, // 事件悬停
        eventClick: this.eventClick, // 事件点击
        dayClick: this.handleDateClick, // 天点击
        editable: false // 是否允许修改事件
      },
      scheduleType: [
        { key: '01', value: '需求' },
        { key: '02', value: '日程' },
        { key: '03', value: '缺陷' }
      ],
      planForm: { // 新增日程
        title: '', // 标题

        hours: '', // 工时
        cycleTime: [], // 开始日期和结束日期的集合
        creator: '',
        role: '',
        startDate: '',
        endDate: ''

      },
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      lookName: '我',
      formLabelWidth: '120px',
      queryWorkData: {
        workingType: ['01', '02', '03'],
        code: window.sessionStorage.getItem('accountCode')
      }, // 所有日程数据
      myAccountCode: '', // 我的账户名
      myAccountName: '我',
      userList: [] // 公司人名
    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.myAccountCode = window.sessionStorage.getItem('accountCode')
    this.myAccountName = window.sessionStorage.getItem('nickName')
    this.getUserList()
    this.getWorkCalendar()
    // this.getWorkDate()
  },

  methods: {
    // async getWorkDate () {
    //   const { data: res } = await this.$http.post('api/tool/getWorkDate', this.WorkDateForm)
    //   if (!res.success) return this.$message.error(res.msg)
    //   this.weekDates = res.data[0]

    //   this.weekFirst = res.data[0].monday
    // },

    eventMouseover: function (calEvent, jsEvent) {
      let content = '' // content中可以直接设置悬浮框中内容的样式

      if (calEvent.workingType === '01') {
        content = "<div style=''>" + calEvent.title + '</div>'
        // content = content + "<div style=''>" + '排期环节：' + calEvent.link + '<div>'
        content = content + "<div style=''>" + '工时：' + calEvent.days + '天/人' + '<div>'
        content = content + "<div style=''>" + '状态：' + calEvent.statusStr + '<div>'
        content = content + "<div style=''>" + '需求周期：' + calEvent.start.format('yyyy-MM-DD') + '到' + calEvent.actualEnd + '<div>'
      } else if (calEvent.workingType === '02') {
        content = "<div style=''>" + calEvent.title + '</div>'
        content = content + "<div style=''>" + '状态：' + calEvent.statusStr + '<div>'
        content = content + "<div style=''>" + '开始日期：' + calEvent.start.format('yyyy-MM-DD') + '<div>'
        content = content + "<div style=''>" + '会议时间：' + calEvent.startTime + '-' + calEvent.endTime + '<div>'
      } else if (calEvent.workingType === '03') {
        content = "<div style=''>" + calEvent.title + '</div>'
        content = content + "<div style=''>" + '状态：' + calEvent.statusStr + '<div>'
        // content = content + "<div style=''>" + '工时：' + calEvent.days + '时/天/人' + '<div>'
        content = content + "<div style=''>" + '任务周期：' + calEvent.start.format('yyyy-MM-DD') + '到' + calEvent.actualEnd + '<div>'
      }

      tippy(jsEvent.currentTarget, {
        content: content, // 悬浮框展示的内容
        // theme: 'light',  //悬浮框主题，默认主题中的light主题（白底黑字）
        theme: 'tomato', // 自定义主题，颜色在style中设置
        allowHTML: true // 为true的时候，可以识别content中的html
      })
    },
    eventClick: function (calEvent) {
      if (calEvent.workingType === '01' || calEvent.workingType === '03') {
        window.open(calEvent.link, '_blank')

      } else if (calEvent.workingType === '02') {
        this.$message.error('请在我的会议中查看详情')
      } else {
        this.$router.push('/demandDetail')
        window.sessionStorage.setItem('tabName', 'first')
        window.sessionStorage.setItem('demandId', calEvent.id)
      }


      // parent.location.href = calEvent.link;
    },
    selectWorking (data) {
      // 根据条件查询日程
      this.queryWorkData.workingType = []
      for (const i in data) {
        if (data[i] === '需求') {
          this.queryWorkData.workingType.push('01')
        } else if (data[i] === '会议') {
          this.queryWorkData.workingType.push('02')
        } else if (data[i] === '缺陷') {
          this.queryWorkData.workingType.push('03')
        } else {
          this.queryWorkData.workingType.push('04')
        }
      }
      this.getWorkCalendar()
    },
    async getWorkCalendar () {
      const { data: res } = await this.$http.post('py/server/project/getWorkCalendar', this.queryWorkData)
      if (!res.success) return this.$message.error(res.msg)
      for (const i in res.data) {
        if (res.data[i].workingType === '01') { res.data[i].title = '需求：' + res.data[i].key + '-' + res.data[i].title }
        if (res.data[i].workingType === '02') { res.data[i].title = '会议：' + res.data[i].key + '-' + res.data[i].title }
        if (res.data[i].workingType === '03') { res.data[i].title = '缺陷：' + res.data[i].key + '-' + res.data[i].title }
      }
      this.events = res.data
      this.queryWorkData.workingType = ['01', '02', '03', '04']
    },
    async addPlan () {
      this.planForm.startDate = this.planForm.cycleTime[0]
      this.planForm.endDate = this.planForm.cycleTime[1]

      const { data: res } = await this.$http.post('api/server/project/addPlan', this.planForm)
      if (!res.success) return this.$message.error(res.msg)
      this.$message.success('添加成功')
      this.dialogFormVisible = false
      this.getWorkCalendar()
    },
    handleCommand (command, data) {
      this.demand_type = true
      this.metting_type = true
      this.work_type = true
      this.checkList = ['需求', '会议', '缺陷', '本地需求']
      this.queryWorkData.code = command
      if (this.myAccountCode === command) {
        this.lookName = '我'
      } else {
        this.lookName = data.$attrs.value
      }

      this.getWorkCalendar()
    },
    async getUserList () {
      /**
       * @description： 获取公司所有人员列表
       *
       */
      const { data: res } = await this.$http.get('py/server/user/getUserList')
      if (!res.success) return this.$message.error(res.msg)
      this.userList = res.data
    },
    handleDateClick (dates) {

      const date = dates.format()
      this.$refs.calendar.fireMethod('changeView', 'basicDay', date)

    },

  }

}
</script>

<style lang="less" scoped>
.table_box {
  margin-left: 50px;
  position: absolute;
  top: 190px;
  font-size: 20px;
}
.select {
  left: 25%;
  position: absolute;
  top: 185px;
  font-size: 20px;
}
::v-deep span.el-checkbox__label {
  font-size: 16px;
}
::v-deep div.fc-content {
  height: 30px;
}
::v-deep .fc-event {
  :hover {
    cursor: pointer;
  }
}
::v-deep .fc-month-view .fc-week {
  height: 100px !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 16px;
}
.el-icon-arrow-down {
  font-size: 20px;
}
.el-dropdown-menu {
  max-height: 200px; /* 设置最大高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
}
::v-deep .fc-event {
  position: relative;
  display: block;
  font-size: 0.3em !important;
  line-height: 1.3;
  border-radius: 3px;
  border: 0px !important;
}
::v-deep div.fc-content {
  height: 20px !important;
}
.parent_box {
  position: relative;
  top: -170px;
}
</style>
