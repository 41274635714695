<template>
	<h1>我是sql查询页面</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>