<template>
    <div>
        <el-form-item label="品牌" prop="brand">
            <el-select v-model="selectedBrand" placeholder="请输入品牌" style="width: 150px" @change="onBrandChange">
                <el-option v-for="brand in brandArr" :key="brand" :label="brand" :value="brand"></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="监管" prop="regulator">
            <el-select v-model="selectedRegulator" placeholder="请输入监管" style="width: 150px">
                <el-option v-for="regulator in regulatorArr" :key="regulator" :label="regulator"
                    :value="regulator"></el-option>
            </el-select>
        </el-form-item>
    </div>

</template>

<script>

import regulatorObj from '../../../public/regulator.json';

export default {

    props: {
        brandArr: {
            type: Array,
            required: true
        },
        regulatorArr: {
            type: Array,
            required: true
        },
        regulatorObj: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    },


    data() {
        return {
            selectedBrand: this.form.brand,
            selectedRegulator: this.form.regulator
        };

    },
    methods: {
        onBrandChange(newVal) {
            if (this.regulatorObj[newVal]) {
                this.selectedRegulator = this.regulatorObj[newVal][0];
            } else {
                console.warn(`regulatorObj[${newVal}] is undefined`);
                this.selectedRegulator = ''; // or any default value
            }
            this.$emit('update:form', {
                ...this.form,
                brand: newVal,
                regulator: this.selectedRegulator
            });
        }

    },

    watch: {
        form: {
            handler(newVal) {
                this.selectedBrand = newVal.brand;
                this.selectedRegulator = newVal.regulator;
            },
            deep: true
        }
    }

}
</script>

<style></style>