<template>
    <div>
        <h3>admin快速审核</h3>
        <el-form :model="adminAuditIdPoaForm" ref="adminAuditIdPoaForm" :inline="true" :rules="rules">
            <AdminEnvAutocomplete :form="adminAuditIdPoaForm"></AdminEnvAutocomplete>
            <div :style="{ whiteSpace: 'pre-line' }"></div>
            <BrandRegulatorSelect :brandArr="brandArr" :regulatorArr="regulatorArr" :regulatorObj="regulatorObj"
                :form.sync="adminAuditIdPoaForm" />
            <el-form-item label="Email" prop="email">
                <el-input v-model="adminAuditIdPoaForm.email" placeholder="请输入email"></el-input>
            </el-form-item>
        </el-form>

        <el-button type="primary" :loading="isLoading" icon="el-icon-edit-outline"
            @click="adminAuditIdPoa('adminAuditIdPoaForm')">id&poa审核</el-button>
        <el-button icon="el-icon-delete" @click="resetForm('adminAuditIdPoaForm')">重置</el-button>
    </div>
</template>

<script>
import regulatorObj from '../../../public/regulator.json';
import AdminEnvAutocomplete from '../component/ChooseEnv.vue';
import BrandRegulatorSelect from '../component/BrandRegulator.vue';

export default {
    name: 'ParentComponent',
    components: {
        BrandRegulatorSelect,
        AdminEnvAutocomplete
    },

    data() {
        const brandArr = Object.keys(regulatorObj)
        return {
            brandArr,
            isLoading: false,
            regulatorObj,
            regulatorArr: ['VFSC', 'VFSC2', 'ASIC', 'FCA'],
            adminAuditIdPoaForm: {
                adminEnv: '',
                regulator: '',
                email: '',
                brand: ''
            },
            rules: {
                brand: [
                    { required: true, message: 'Please input brand', trigger: 'blur' }
                ],
                regulator: [
                    { required: true, message: 'Please input regulator', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入email', trigger: 'blur' }
                ]
            },

        }
    },
    methods: {
        adminAuditIdPoa(formName) {
            this.isLoading = true;
            this.adminAuditIdPoaForm.adminEnv = this.adminAuditIdPoaForm.environment || '';
            console.log(this.adminAuditIdPoaForm.email);
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    try {
                        const res = await this.$http.post(
                            'api/adminIdPoaAudit',
                            this.adminAuditIdPoaForm
                        )
                        this.result = res.data.data
                        this.$message.warning(this.result.result);
                        console.log(this.result)
                        this.isLoading = false;
                    } catch (error) {
                        this.isLoading = false;
                        this.$message.error('API request failed!');
                    }
                } else {
                    console.log('error submit!!');
                    this.isLoading = false;
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        }

    }
}
</script>

<style></style>