<!--数据工具-平台用户-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeNameLast }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form inline
               :model="pageQuery">
        <template>
          <el-tabs v-model="showName"
                   @tab-click="getList">
            <el-tab-pane label="我的数据"
                         name="me">
              <el-form-item prop="code"
                            label="原登录账号">
                <el-input v-model="pageQueryMe.code"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary"
                           size="mini"
                           @click="getMePageList">查询我的数据</el-button>
                <el-button type="primary"
                           size="mini"
                           @click="mobileDialogVisible=true">新增数据</el-button>
              </el-form-item>
              <el-table :data="pageListMe"
                        height="580"
                        :header-cell-style="{ 'text-align': 'center' }"
                        :cell-style="{ 'text-align': 'center' }"
                        border
                        style="width: 100%"
                        stripe>
                <el-table-column type="index"
                                 width="50"
                                 label="序号"> </el-table-column>
                <el-table-column prop="orgName"
                                 label="企业名称"
                                 width="240">
                </el-table-column>

                <el-table-column prop="operatorName"
                                 label="操作员名称"
                                 width="180">
                </el-table-column>
                <el-table-column prop="mobile"
                                 label="手机号"
                                 width="180">
                </el-table-column>

                <el-table-column prop="roleType"
                                 label="角色"
                                 width="180">
                </el-table-column>

                <el-table-column prop="envStr"
                                 label="环境"
                                 width="120">
                </el-table-column>
                <el-table-column prop="operatorCode"
                                 label="原登录账号"
                                 width="120">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-tooltip placement="top"
                                content="查询短信"
                                :enterable="false">
                      <el-button type="primary"
                                 size="mini"
                                 @click="getRecordList(scope.row.mobile)">短信查询</el-button>
                    </el-tooltip>
                    <el-tooltip placement="top"
                                content="删除"
                                :enterable="false">
                      <el-button type="warning"
                                 size="mini"
                                 @click="deletePlatUser(scope.row.operatorCode)">删除</el-button>
                    </el-tooltip>

                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChangeMe"
                             @current-change="handleCurrentChangeMe"
                             :current-page="pageQueryMe.current"
                             :page-sizes="[10, 20, 30, 40]"
                             :page-size="pageQueryMe.size"
                             layout="total, sizes, prev, pager, next, jumper"
                             :total="totalMe">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane label="所有数据"
                         name="all">
              <el-form-item prop="code"
                            label="原登录账号">
                <el-input v-model="pageQuery.code"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary"
                           size="mini"
                           @click="getPageList">查询所有数据</el-button>
                <el-button type="primary"
                           size="mini"
                           @click="query('LG_UAT_PORTAL')">切换至陆港UAT</el-button>
                <el-button type="primary"
                           size="mini"
                           @click="query('LG_FAT_PORTAL')">切换至陆港FAT</el-button>
                <el-button type="primary"
                           size="mini"
                           @click="query('LG_FAT_PORTAL')">切换至E诺FAT</el-button>
              </el-form-item>
              <el-table :data="pageList"
                        height="580"
                        border
                        style="width: 100%"
                        stripe>
                <el-table-column type="index"
                                 width="50"> </el-table-column>
                <el-table-column prop="code"
                                 label="原登录账号"
                                 width="240">
                </el-table-column>
                <el-table-column prop="mobile"
                                 label="手机号"
                                 width="240">
                </el-table-column>
                <el-table-column prop="name"
                                 label="名称"
                                 width="300">
                </el-table-column>
                <el-table-column prop="roleStr"
                                 label="角色"
                                 width="240">
                </el-table-column>
                <el-table-column prop="envStr"
                                 label="环境"
                                 width="240">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-tooltip placement="top"
                                content="添加"
                                :enterable="false">
                      <el-button type="primary"
                                 size="mini"
                                 @click="addPlatUser(scope.row)">添加至我的数据</el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange"
                             @current-change="handleCurrentChange"
                             :current-page="pageQuery.current"
                             :page-sizes="[10, 20, 30, 40]"
                             :page-size="pageQuery.size"
                             layout="total, sizes, prev, pager, next, jumper"
                             :total="total">
              </el-pagination>
            </el-tab-pane>
          </el-tabs>
        </template>
      </el-form>
    </el-card>
    <el-dialog title="短信数据"
               :visible.sync="dialogVisible"
               width="70%">
      <RecordList :list="
               this.pageList"></RecordList>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="closeDialogVisible">取 消</el-button>
        <el-button type="primary"
                   @click="closeDialogVisible">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增数据"
               :visible.sync="mobileDialogVisible"
               width="25%"
               :close-on-click-modal="false">
      <el-form ref="addMobileForm"
               :model="addMobileForm"
               :rules="addMobileFormRule">
        <el-form-item label="企业名称"
                      :label-width="formLabelWidth"
                      prop="orgName">
          <el-input v-model="addMobileForm.orgName"
                    placeholder="请输入操作员名称"
                    style="width: 218px;">

          </el-input>
        </el-form-item>
        <el-form-item label="操作员名称"
                      :label-width="formLabelWidth"
                      prop="operatorName">
          <el-input v-model="addMobileForm.operatorName"
                    placeholder="请输入操作员名称"
                    style="width: 218px;">

          </el-input>
        </el-form-item>
        <el-form-item label="手机号："
                      :label-width="formLabelWidth"
                      prop="mobile">
          <el-input v-model="addMobileForm.mobile"
                    placeholder="请输入手机号"
                    style="width: 218px;">

          </el-input>
        </el-form-item>

        <el-form-item label="选择环境："
                      :label-width="formLabelWidth"
                      prop="envStr">

          <el-select v-model="addMobileForm.envStr"
                     @change="setEnvStr"
                     placeholder="请选择环境">
            <el-option v-for="item in env"
                       :key="item.key"
                       :label="item.value"
                       :value="item"></el-option>

          </el-select>

        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="closeMobileDialogVisible">取 消</el-button>
        <el-button type="primary"
                   @click="addMobile">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import RecordList from '../record/RecordList.vue'

export default {
  components: {
    RecordList
  },
  created () {
    this.getEnv()
    this.getPageList()
    this.getMePageList()

    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
  },
  data () {
    return {
      pageList: [],
      mobileDialogVisible: false,
      showName: 'me',
      pageListMe: [],
      pageQuery: {
        current: 1,
        size: 10,
        code: '',
        env: '',
        envStr: ''
      },
      pageQueryMe: {
        current: 1,
        size: 10,
        code: '',
        env: '',
        envStr: '',
        mobile: ''
      },
      addPlatUserInfo: {
        operatorCode: '',
        operatorName: '',
        mobile: '',
        roleType: '',
        orgName: '平台用户'
      },
      addMobileForm: {
        operatorCode: '',
        operatorName: '',
        mobile: '',
        roleType: '',
        envStr: '',
        orgName: ''
      },
      addMobileFormRule: {
        orgName: [
          { required: true, message: '请输入企业名称', trigger: 'bulur' }
        ],
        operatorName: [
          { required: true, message: '请输入操作员名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
        ],
        envStr: [
          { required: true, message: '请选择环境', trigger: 'blur' }
        ]

      },
      total: 0,
      totalMe: '',
      activePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      activeNameLast: '我的数据',
      dialogVisible: false,
      formLabelWidth: '120px',
      env: [],
      userTypeList: []// 用户分组枚举列表
    }
  },
  methods: {
    async getPageList () {
      const { data: res } = await this.$http.post(
        'api/server/test/data/getPlatUserPageList',
        this.pageQuery
      )
      if (res.success) {
        this.pageList = res.data.records
        this.total = res.data.total
      }
    },
    getList (tab, event) {
      this.activeNameLast = tab.label
    },
    async addPlatUser (userInfo) {
      this.addPlatUserInfo.operatorCode = userInfo.code
      this.addPlatUserInfo.operatorName = userInfo.name
      this.addPlatUserInfo.mobile = userInfo.mobile
      this.addPlatUserInfo.roleType = userInfo.roleStr
      this.addPlatUserInfo.envStr = userInfo.envStr
      const { data: res } = await this.$http.post(
        'api/server/test/data/addPlatUser',
        this.addPlatUserInfo
      )
      if (res.success) {
        this.$message.success('添加成功')
        this.getMePageList()
      } else {
        this.$message.error('数据已存在')
      }
    },
    async deletePlatUser (operatorCode) {
      const { data: res } = await this.$http.post(
        'api/server/test/data/deletePlatUser?operatorCode=' + operatorCode
      )
      if (res.success) {
        this.$message.success('删除成功')
        this.pageQueryMe.current = 1
        this.getMePageList()
      } else {
        this.$message.error('删除失败')
      }
    },
    async getMePageList () {
      const { data: res } = await this.$http.post(
        'api/server/test/data/getPagePlatUser',
        this.pageQueryMe
      )
      if (res.success) {
        this.pageListMe = res.data.records
        this.totalMe = res.data.total
      }
    },
    async getEnv () {
      const { data: res } = await this.$http.get('api/server/test/data/getEnv')
      if (res.success) {
        this.env = res.data
        console.log(this.env)
      }
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage
      this.getPageList()
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent
      this.getPageList()
    },
    handleSizeChangeMe (newPage) {
      this.pageQueryMe.size = newPage
      this.getMePageList()
    },
    handleCurrentChangeMe (newCurrent) {
      this.pageQueryMe.current = newCurrent
      this.getMePageList()
    },
    query (portal) {
      this.pageQuery.env = portal
      this.getPageList()
    },
    async getRecordList (mobile) {
      this.dialogVisible = true
      this.pageQuery.mobile = mobile

      const { data: res } = await this.$http.post(
        'api/DangBan/SMS/getPageList',
        this.pageQuery
      )
      if (res.success) {
        this.pageList = res.data.records
        this.total = res.data.total
      }
    },
    addMobile () {
      this.$refs.addMobileForm.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          'api/server/test/data/addOrgUser',
          this.addMobileForm
        )
        if (res.success) {
          this.$message.success('添加成功')
          this.mobileDialogVisible = false
          this.$refs.addMobileForm.resetFields()
          this.getMePageList()
        } else {
          this.$message.error('数据已存在')
        }
      })
    },
    closeDialogVisible () {
      this.dialogVisible = false
    },
    closeMobileDialogVisible () {
      this.mobileDialogVisible = false
      this.$refs.addMobileForm.resetFields()
    },
    setEnvStr (e) {
      console.log(e)
      this.addMobileForm.envStr = e.value
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
