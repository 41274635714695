<template>

  <el-container class="home-container">
    <!--头部区域-->
    <el-header>
      <div style="margin-left: 10px;">
        <img src="../assets/img/login_logo.af1ccbb1.png"
             width="150px"
             alt="">
        <span style="font-weight: bolder;">办公助手</span>
      </div>

      <div style="position: absolute;left:80%;">
        <el-avatar icon="el-icon-user-solid"></el-avatar>
        <span>{{nickName}}</span>
        <div><span style="font-size: 12px;">{{roleType}}</span></div>

      </div>
      <el-button type="info"
                 @click="logout">退出</el-button>
    </el-header>
    <!--页面主体区-->
    <el-container ref="homePage">
      <!--左侧导航栏-->
      <el-aside :width="isCollapse ? '0px':'200px'">
        <div class="toggle-button"
             @click="toggleCollapse"
             :style="{marginLeft: isCollapse ? '0px' :' 200px'}">
          <i class="el-icon-arrow-right"
             v-if="isCollapse"></i>
          <i class="el-icon-arrow-left"
             v-else></i>
        </div>
        <!--侧边栏菜单区域-->
        <el-menu background-color="#333744"
                 text-color="#fff"
                 active-text-color="#409EFF"
                 :unique-opened="true"
                 :collapse="isCollapse"
                 :collapse-transition="false"
                 :router="true"
                 :default-active="activePath ? activePath : '/show'">

          <!--一级菜单-->
          <div v-for="item in menuList"
               :key="item.id"
               :class="item.code">
            <el-submenu :index="'/'+item.code">
              <!--一级菜单模板区-->

              <div slot="title"
                   v-if="item.name == '首页'"
                   @click="houseActive('/'+item.code, item.name, item.name)">
                <!--图标-->
                <i :class="item.icon"></i>
                <!--文本-->
                <span>{{ item.name}}</span>
              </div>
              <div slot="title"
                   v-else>
                <!--图标-->
                <i :class="item.icon"></i>
                <!--文本-->
                <span>{{ item.name}}</span>
              </div>

              <!--二级菜单-->
              <el-menu-item :index="'/'+subitem.code"
                            v-for="subitem in item.children"
                            :key="subitem.id"
                            @click="saveActive('/'+subitem.code, subitem.name, item.name)">
                <template slot="title">

                  <span>{{ subitem.name }}</span>

                </template>
              </el-menu-item>

            </el-submenu>
          </div>

        </el-menu>

      </el-aside>
      <!--右侧内容区-->
      <el-main>
        <!--路由占位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>

export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [],
      nickName: '',
      iconsObj: {
        4: 'el-icon-aim',
        1: 'el-icon-s-tools'
      },
      // 是否折叠， false默认 不折叠
      isCollapse: false,
      // 给activePath定义
      activePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      clientHeight: '',
      roleType: ''// 登录人的 角色
    }
  },
  created () {
    this.getMenuList()
    this.nickName = window.sessionStorage.getItem('nickName')
    this.roleType = window.sessionStorage.getItem('roleType')
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    // 获取浏览器可视区域高度
    this.clientHeight = `${document.documentElement.clientHeight}`
    // document.body.clientWidth;
    // console.log(self.clientHeight);
    window.onresize = function temp () {
      this.clientHeight = `${document.documentElement.clientHeight}`
    }
  },
  watch: {
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    clientHeight: function () {
      this.changeFixed(this.clientHeight)
    }
  },
  methods: {
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList () {
      const { data: res } = await this.$http.get('py/server/menu/getMenu')
      if (res.success !== true) { return this.$message.error(res.msg) }
      this.menuList = res.data
    },
    // 点击按钮， 切换菜单的折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    saveActive (activePath, activeName, parentName) {
      window.sessionStorage.setItem('activePath', activePath)
      window.sessionStorage.setItem('activeName', activeName)
      window.sessionStorage.setItem('parentName', parentName)
      this.activePath = activePath
      this.activeName = activeName
      this.parentName = parentName
    },
    houseActive (activePath, activeName, parentName) {
      window.sessionStorage.setItem('activePath', activePath)
      window.sessionStorage.setItem('activeName', activeName)
      window.sessionStorage.setItem('parentName', parentName)
      this.activePath = activePath
      this.activeName = activeName
      this.parentName = parentName
      this.$router.push('/show')
    },
    changeFixed (clientHeight) { // 动态修改样式
      // console.log(clientHeight);
      // console.log(this.$refs.homePage.$el.style.height);
      this.$refs.homePage.$el.style.height = clientHeight - 20 + 'px'
    }
  }
}

</script>

<style lang="less" scoped>
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  img {
    height: 30px;
    align-items: center;
  }
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.aside_menu {
  display: flex;
  padding-top: 0;
}

.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #333744;
  color: #333;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .el-menu {
    border-right: none;
  }

  .el-menu-item.is-active {
    background-color: rgb(46, 50, 61) !important;
  }
}

.el-main {
  background-color: #eaedf1;
  color: #333;
}
.home-container {
  height: 100%;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-left: 200px;
}
.show {
  .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }
  //菜单展开
  .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }
}
.el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotateZ(-90deg);
}
//菜单展开
.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: rotateZ(0deg);
  -ms-transform: rotate(0deg);
  transform: rotateZ(0deg);
}
</style>
